import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import "tippy.js/dist/tippy.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './_assets/css/global.scss';
import './_assets/css/bootstrap-custom.scss';
import Nav from './components/header/nav';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import Icon from './components/body/icon';
import { GlobalContext } from './context/Global';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Cropper from './components/body/upload/components/Cropper';
import PageError from './components/body/pageError';

// SISTEMAS
import Home from './pages/Home';
import Jobs from './pages/Jobs';
import Gerenciador from './pages/Gerenciador';
import Checklist from './pages/Checklist';
import Treinamento from './pages/Treinamento';
import Trade from './pages/Trade';
import Avaliacao from './pages/Avaliacao';
import Autoavaliacao from './pages/Autoavaliacao';
import Nps from './pages/Nps';
import Vm from './pages/Vm';
import Lote from './pages/Lote-Validade';
import Lojas from './pages/Lojas';
import News from './pages/News';
import Vendas from './pages/Vendas';
import Documentation from './pages/Documentation';
import Documentos from './pages/Documentos';
import Microssistemas from './pages/Microssistemas';
import Obras from './pages/Obras';
import Galeria from './pages/Galeria';
import FechamentoCaixa from './pages/Fechamento-Caixa';
import Escala from './pages/Escala';
import Dashboards from './pages/Dashboards';
import Cockpit from './pages/Cockpit';
import ControleTemperatura from './pages/ControleTemperaturas';
import Sac from './pages/Sac';
import Gis from './pages/Gis';
import Funcionarios from './pages/Funcionarios';

function App() {   
  // CONTEXT
  const { pageError, src, openImageCropper, toggler, handleSetToggler, sources, sourceIndex } = useContext(GlobalContext);

  // ESTADOS LIGHTBOX
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(2);
  const [zoomInMultiplier, setZoomInMultiplier] = useState(5);
  const [doubleTapDelay, setDoubleTapDelay] = useState(300);
  const [doubleClickDelay, setDoubleClickDelay] = useState(300);
  const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
  const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
  const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] = useState(100);
  const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] = useState(100);
  const [scrollToZoom, setScrollToZoom] = useState(false);

  // ESTADOS
  const [width, setWidth] = useState(window.innerWidth);    

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  window.isMobile = width <= 768;

  //PEGAR DATA E HORA ATUAL
  var currentDateValue = new Date();
  var currentDay = currentDateValue.getDate();
  var currentMonth = currentDateValue.getMonth() + 1;
  var currentYear = currentDateValue.getFullYear();
  var currentHour = currentDateValue.getHours();
  var currentMinutes = currentDateValue.getMinutes();
  var currentSeconds = currentDateValue.getSeconds();
  var currentLastDatemMonth = new Date(currentDateValue.getFullYear(), currentDateValue.getMonth() + 1, 0);
  window.currentDate = currentYear + "-" + (currentMonth < 10 ? "0" + currentMonth : currentMonth) + "-" + (currentDay < 10 ? "0" + currentDay : currentDay) + " " + (currentHour < 10 ? "0" + currentHour : currentHour) + ":" + (currentMinutes < 10 ? "0" + currentMinutes : currentMinutes) + ":" + (currentSeconds < 10 ? "0" + currentSeconds : currentSeconds);
  window.currentDateWithoutHour = currentYear + "-" + (currentMonth < 10 ? "0" + currentMonth : currentMonth) + "-" + (currentDay < 10 ? "0" + currentDay : currentDay);
  window.currentHour = (currentHour < 10 ? '0' + currentHour : currentHour);
  window.currentMinutes = (currentMinutes < 10 ? '0' + currentMinutes : currentMinutes);
  window.currentDay = currentDay;
  window.currentMonth = currentMonth;
  window.currentMonthLastDay = currentLastDatemMonth.getDate();
  window.currentYear = currentYear;
  window.producao = true;
  window.host = process.env.REACT_APP_URL_PROD;
  window.host_madnezz = process.env.REACT_APP_URL_PROD_MADNEZZ;
  window.upload = process.env.REACT_APP_URL_UPLOAD;
  window.backend = 'https://backend.madnezz.com.br';

  // OPTIONS PADRÕES 
  window.optionsMonths = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' }
  ]

  window.optionsYear = [];
  for (var i = 0; i < 5; i++) {
    window.optionsYear.push({ value: window.currentYear - i, label: window.currentYear - i })
  }

  if(!navigator.userAgent.match(/firefox|fxios/i)){ // SE NÂO FOR FIREFOX NÃO TEM COMO VERIFICAR A URL (SETA PRODULÃO SEMPRE COMO TRUE)
    // URL SISTEMA
    if (window?.location?.ancestorOrigins[0]?.includes('dev.')) {
      window.producao = false;
      window.host = process.env.REACT_APP_URL_DEV;
      window.host_madnezz = process.env.REACT_APP_URL_DEV_MADNEZZ;
    } else if (window?.location?.ancestorOrigins[0]?.includes('.test')) {
      window.producao = false;
      window.host = process.env.REACT_APP_URL_LOCAL;
      window.host_madnezz = process.env.REACT_APP_URL_LOCAL_MADNEZZ;
      // window.host = process.env.REACT_APP_URL_DEV;
      // window.host_madnezz = process.env.REACT_APP_URL_DEV_MADNEZZ;
      // window.host = process.env.REACT_APP_URL_PROD;
      // window.host_madnezz = process.env.REACT_APP_URL_PROD_MADNEZZ;
    }
  }

  // ID DE MÓDULOS FIXOS
  global.modulo = {
    visitas: 2421
  }

  // ID DE SISTEMAS
  global.sistema = {
    checklist: 1
  }

  // ID DE FREQUÊNCIAS
  global.frequencia = {
    unico: 143,
    diario: 1,
    semanal: 2,
    sabado: 154,
    domingo: 155,
    mensal: 3,
    anual: 2420
  }

  // ID DE CATEGORIAS FIXAS
  global.categoria = {
    plano_de_acao: 2426
  }

  // ID DE SUBCATEGORIAS FIXAS
  global.subcategoria = {
    checklist: 4291
  }

  // COMPONENTES MICROSSISTEMA
  global.componentes = {
    select_personalizado: 7,
    checkbox_personalizado: 32,
    data: 8,
    select_loja: 10
  }
  
  return (
    <>
      <Router>
        <header>
          <Nav></Nav>
        </header>
        
        {(!pageError ? 
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/documentation/*" element={ <Documentation /> } />
            <Route path="/systems/home-react/*" element={ <Home /> } />
            <Route path="/systems/documentos-react/*" element={ <Documentos /> } />
            <Route path="/systems/job-react/*" element={ <Jobs /> } />
            <Route path="/systems/microssistemas-react/*" element={ <Microssistemas /> } />
            <Route path="/systems/gerenciador-react/*" element={ <Gerenciador /> } />
            <Route path="/systems/checklist-react/*" element={ <Checklist /> } />
            <Route path="/systems/treinamento-react/*" element={ <Treinamento /> } />
            <Route path="/systems/chamados-react/*" element={ <Jobs chamados={true} /> } />
            <Route path="/systems/chamados-empresa-react/*" element={ <Jobs chamados={true} /> } />
            <Route path="/systems/fases-react/*" element={ <Jobs fases={true} /> } />
            <Route path="/systems/trade-react/*" element={ <Trade /> } />
            <Route path="/systems/autoavaliacao/*" element={ <Autoavaliacao /> } />
            <Route path="/systems/nps/*" element={ <Nps /> } />
            <Route path="/systems/vm-react/*" element={ <Vm /> } />
            <Route path="/systems/avaliacao-react/*" element={ <Avaliacao /> } />
            <Route path="/systems/lojas-react/*" element={ <Lojas /> } />
            <Route path="/systems/lote/*" element={ <Lote /> } />
            <Route path="/systems/visitas-react/*" element={ <Jobs chamados={false} fases={false} visitas={true} /> } />
            <Route path="/systems/news-react/*" element={ <News /> } />
            <Route path="/systems/vendas-react/*" element={ <Vendas /> } />
            <Route path="/systems/obras-react/*" element={ <Obras /> } />
            <Route path="/systems/galeria-react/*" element={ <Galeria />} />
            <Route path="/systems/fechamento-caixa-react/*" element={ <FechamentoCaixa />} />
            <Route path="/systems/escala-react/*" element={ <Escala />} />
            <Route path="/systems/dashboards-react/*" element={ <Dashboards />} />
            <Route path="/systems/cockpit-react/*" element={ <Cockpit />} />
            <Route path="/systems/controle-temperaturas-react/*" element={ <ControleTemperatura />} />
            <Route path="/systems/sac-react/*" element={ <Sac />} />
            <Route path="/systems/gis-react/*" element={<Gis />}  />
            <Route path="/systems/funcionarios-react/*" element={<Funcionarios />} />
          </Routes>
        :
          <PageError />
        )}
      </Router>        

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: 'toaster',
          duration: 4000,
          style: {
            background: 'rgba(0,0,0,0.9)',
            color: '#fff',
            fontSize: 13,
            padding: '10px 18px',
            borderRadius: 6,
          }
        }}
        containerStyle={{
          bottom: (window.isMobile ? 100 : 20),
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                <div className="toaster__icon">
                  <Icon type="message" />
                </div>
                {message}
                {t.type !== 'loading' && (
                  <button onClick={() => toast.dismiss(t.id)}>
                    <Icon type="reprovar" title={false}></Icon>
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>

      {/* RECORTE AO FAZER UPLOAD DE IMAGENS */}
      {(openImageCropper && src ? 
        <Cropper
          // ref={spanRef}
          src={src}
          // name={(file && file[0].name.split(".")[0]) || "image"}
          // onSave={onSaveCropped}
          config={{
            type: "file",
            iconSrc: "https://img.icons8.com/ios/256/camera--v3.png",
            config: {
              maxZoom: 10,
              aspectRatio: 4 / 3,
            }
          }}
        />
      :'')}

      {/* LIGHTBOX */}
      <Lightbox
        open={toggler}
        // plugins={[Fullscreen, Zoom]}
        close={() => handleSetToggler(false)}
        slides={sources}
        index={sourceIndex}
        carousel={{
          finite: true,
          preload: 10
        }}
        plugins={[Zoom, Fullscreen]}
        zoom={{
          maxZoomPixelRatio,
          zoomInMultiplier,
          doubleTapDelay,
          doubleClickDelay,
          doubleClickMaxStops,
          keyboardMoveDistance,
          wheelZoomDistanceFactor,
          pinchZoomDistanceFactor,
          scrollToZoom
        }}
        thumbnails={{
          position: 'bottom'
        }}
      />
    </>
  );
}

export default App;
