import { useState, useEffect } from "react";
import { addDays, subDays } from "../../../../_assets/js/global";

import style from "./Input.module.scss";
import ReactDatePicker from "react-datepicker";
import Icon from "../../icon";
import Tippy from "@tippyjs/react";
import InputMask from "react-input-mask";
import Upload from "../../upload/Upload";
import CurrencyInput from "react-currency-input-field";

export default function Input(props) {
  // ESTADOS
  const [focus, setFocus] = useState(null);
  const [fileValue, setFileValue] = useState(null);
  const [fileValueName, setFileValueName] = useState(null);
  const [security, setSecurity] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [typeAux, setTypeAux] = useState('password');

  //CONFIGURAÇÕES DE LANGUAGE PRO DATEPICKER
  const defaultDays = ["D", "S", "T", "Q", "Q", "S", " S"];
  var defaultMonths;

  if(props.format){
    if(props.format == 'mm/yyyy'){
      defaultMonths = [
        "Jan.",
        "Fev.",
        "Mar.",
        "Abr.",
        "Mai.",
        "Jun.",
        "Jul.",
        "Ago.",
        "Set.",
        "Out.",
        "Nov.",
        "Dez.",
      ];
    }else{
      defaultMonths = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
    }
  }else{
    defaultMonths = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
  }

  const locale = {
    localize: {
      day: (n) => defaultDays[n],
      month: (n) => defaultMonths[n],
    },
    formatLong: {
      date: () => "dd/mm/yyyy",
    },
  };

  // FUNÇÃO PARA PEGAR VALOR INPUT FILE
  function handleFileValue(value){
    let parts = value.split('\\');
    let result = parts[parts.length - 1];
    setFileValue(value);
    setFileValueName(result);
  }

  // CALCULA SEGURANÇA DA SENHA
  function handleSecurity(e){
    let qtd = false;
    let special = false;
    let number = false;

    // VERIFICA SE POSSUI CARACTER ESPECIAL
    if(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e)){
      special = true;
    }else{
      special = false;
    }

    // VERIFICA SE TEM AO MENOS 6 CARACTERES
    if(e.length>=6){
      qtd = true;
    }else{
      qtd = false;
    }

    // VERIFICA SE TEM ALGUM NÚMERO
    if(/\d/.test(e)){
      number = true;
    }else{
      number = false;
    }

    if(special&&!qtd){
      setSecurity('weak');
    }

    if(!special&&qtd){
      setSecurity('weak');
    }

    if(special&&qtd){
      setSecurity('median');
    }

    if(special&&qtd&&number){
      setSecurity('strong');
    }

    if(!special&&!qtd){
      setSecurity('weak');
    }

    if(e.length==0){
      setSecurity('');
    }
  }

  // FOCUS INPUT
  const handleSetFocus = (e) => {
    if(props?.focusSelect){
      e.target.select();
    }
    setFocus(true);
  }

  // FUNÇÃO AO TIRAR FOCO DO INPUT
  const handleFocusOut = () => {        
    if(focus){
        setFocus(false);
    }
  }

  useEffect(() => {
      if(focus === false) {
          if (props.onFocusOut) {
              props.onFocusOut(true);
          }
      }
  }, [focus])

  // VER SENHA
  const handleShowPassword = () => {
    setViewPassword(!viewPassword);
    setTypeAux(!viewPassword ? 'text' : 'password')
  }

  return (
    <div
      data-input={true}
      data-disabled={(props.disabled === true ? true : false)}
      className={
        style.box__input +
        " " +
        (props.fullWidth ? "w-100" : "") +
        " " +
        (props.className ? props.className : "") +
        " " +
        (props.type == "checkbox" ? style.checkbox : "") +
        " " +
        (props.type == "radio" ? style.radio : "") +
        " " +
        (props.type == "period" ? style.period : "") +
        " " +
        (props.border === false ? style.no__border : "") +
        " " +
        (props.padding === false ? style.no_padding : '') +
        " " +
        (props.background === false ? style.no_background : '') +
        " " +
        (props.size ? style.icon__size_lg : '') +
        " " +
        (props.loading ? style.loading : '') +
        " " +
        (focus ? style.box__input_focus : "") + 
        ' position-relative ' + 
        (props.inverse ? style.reverse:'') +
        (props.disabled? style.disabled:'') + 
        (props.readonly? style.readonly:'') + 
        (props.type === 'date' && props.noWeekend ? style.no_weekend:'')
      }
      style={{
        display: (props.type === "hidden" || props.hide ? "none" : ""),
        marginBottom: (props.border === false ? 0 : '')
      }}
    >
      {(() => {
        if(!props?.loading){
          return(
            <>
              {(() => {
                if (
                  props.label &&
                  props.type !== "date" &&
                  props.type !== "period" &&
                  props.type !== "checkbox" &&
                  props.type !== "radio"
                ) {
                  return (
                    <label htmlFor={props.id}>
                      {props.label}: {props.required === false ? "" : "*"}
                    </label>
                  );
                }
              })()}
        
              {(() => {
                if (props.type === "date") {
                  return (
                    <>
                      {(props?.label !== false ?
                        <label htmlFor={props.id}>
                          {props.label ? props.label : "Data"}:{" "}
                          {props.required === false ? "" : "*"}
                        </label>
                      :'')}

                      <ReactDatePicker
                        locale={locale}
                        dateFormat={(props?.format == 'mm/yyyy' ? 'MM/yyyy' : 'dd/MM/yyyy')}
                        onFocus={(e) => e.target.readOnly = true}
                        selected={props.value}
                        value={props.value}
                        onChange={props.onChange}
                        required={(props.required===false?false:true)}
                        showMonthYearPicker={(props?.format === 'mm/yyyy' ? true : false)}
                        includeDateIntervals={[
                          {
                            start: subDays(props.valueStart ? props.valueStart : new Date("01/01/1900"), 1),
                            end: (props?.valueEnd ? props?.valueEnd : addDays(props.valueStart ? props.valueStart : new Date("01/01/3999"), 100000)),
                          },
                        ]}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="react-datepicker__header_control">
                            <span
                              className="cursor-pointer"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </span>
        
                            <span>
                              {(props?.format == 'mm/yyyy' ? 
                                <>{date.getFullYear()}</>
                              :
                                <>{defaultMonths[date.getMonth()]} {date.getFullYear()}</>
                              )}
                            </span>
        
                            <span
                              className="cursor-pointer"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </span>
                          </div>
                        )}
                      />
                    </>
                  );
                } else if (props.type === "date-month") {
                  return (
                    <>
                      <label htmlFor={props.id}>
                        {props.label ? props.label : "Data"}:{" "}
                        {props.required === false ? "" : "*"}
                      </label>
                      <ReactDatePicker
                        locale={locale}
                        dateFormat="MM"
                        onFocus={(e) => e.target.readOnly = true}
                        selected={props.value}
                        value={props.value}
                        onChange={props.onChange}
                        required={(props.required===false?false:true)}
                        includeDateIntervals={[
                          {
                            start: subDays(props.valueStart?props.valueStart:new Date("01/01/1900"), 1),
                            end: addDays(
                              props.valueStart
                                ? props.valueStart
                                : new Date("01/01/3999"),
                              100000
                            ),
                          },
                        ]}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="react-datepicker__header_control">
                            <span
                              className="cursor-pointer"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </span>
        
                            <span>
                              {defaultMonths[date.getMonth()]} {date.getFullYear()}
                            </span>
        
                            <span
                              className="cursor-pointer"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </span>
                          </div>
                        )}
                      />
                    </>
                  );
                } else if (props.type === "period") {
                  return (
                    <>
                      <div className={style.input__period}>
                        <label htmlFor={props.id}>De:</label>
                        <ReactDatePicker
                          locale={locale}
                          dateFormat="dd/MM/yyyy"
                          selected={props?.valueStart}
                          value={props?.valueStart}
                          onChange={props?.onChangeStart}
                          onFocus={(e) => e.target.readOnly = true}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="react-datepicker__header_control">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}
                              </button>
        
                              <span>
                                {defaultMonths[date.getMonth()]} {date.getFullYear()}
                              </span>
        
                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </div>
        
                      <div className={style.input__period}>
                        <label htmlFor={props.id}>Até:</label>
                        <ReactDatePicker
                          locale={locale}
                          dateFormat="dd/MM/yyyy"
                          selected={props?.valueEnd}
                          value={props?.valueEnd}
                          onChange={props?.onChangeEnd}
                          onFocus={(e) => e.target.readOnly = true}
                          includeDateIntervals={[
                            {
                              start: (props?.valueStart ? subDays(props.valueStart, 1) : ''),
                              end: (props.valueStart ? addDays(props.valueStart, 100000) : ''),
                            },
                          ]}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="react-datepicker__header_control">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}
                              </button>
        
                              <span>
                                {defaultMonths[date.getMonth()]} {date.getFullYear()}
                              </span>
        
                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </>
                  );
                } else if(props.type  === 'file' || props.type  === 'image') {
                  return(
                    <>
                      {(fileValue ? 
                        <span className={style.input__file}>
                          {(fileValue?
                            <Icon
                              type="reprovar"
                              title="Remover anexo"
                              onClick={() => (setFileValue(null), setFileValueName(null))}
                              readonly={props?.readonly}
                            />
                          :'')}
                        </span>
                      :'')}
        
                      <Upload
                        type={props?.type}
                        icon={props?.icon}
                        title={props?.title}
                        accept={props?.accept}
                        capture={props?.capture}
                        align={props?.align}
                        multiple={(props?.multiple === false ? false : true)}
                        multipleValues={props?.multipleValues}
                        propsValue={props?.value}
                        onChange={props?.onChange}
                        callback={props.callback}
                        className={(props.border === false ? 'onlyIcon' : '')}
                        size={props.size}
                        maxSize={props?.maxSize}
                        readonly={props?.readonly}
                        disabled={props?.disabled}
                        input={props?.input}
                      />
                    </>
                  );
                } else if(props.type === 'checkbox' || props.type === 'radio') {
                  return (
                    <Tippy disabled={(props.title?false:true)} content={ props?.title } >
                      <input
                        className={style.input}
                        type={props.type === "date" ? "text" : props.type}
                        name={props.name}
                        id={props.id}
                        placeholder={props.placeholder}
                        value={props.value ? props.value : ""}
                        onChange={props.onChange}
                        required={props.required === false ? false : true}
                        onFocus={() => setFocus(true)}
                        onBlur={handleFocusOut}
                        onClick={props.onClick}                
                        defaultChecked={props?.defaultChecked}
                        checked={props.checked}
                        autoComplete={props.autocomplete ? props.autocomplete : "off"}
                      />
                    </Tippy>
                  );
                } else if(props.type === 'money'){
                  return (
                    <Tippy disabled={(props.title?false:true)} content={ props?.title } >
                      <>           
                        <CurrencyInput
                          id={props.id}
                          name={props.name}
                          placeholder={props.placeholder}
                          defaultValue={props.value ? props.value : ""}
                          decimalsLimit={2}
                          decimalSeparator=","
                          groupSeparator="."
                          onValueChange={props.onChange}
                          className={style.input}
                          required={props.required === false ? false : true}
                          onFocus={(e) => handleSetFocus(e)}
                          onBlur={handleFocusOut}
                          onKeyPress={props?.onKeyPress}
                          onKeyDown={props.onKeyDown}
                          style={props?.style}
                          autoComplete={props.autocomplete ? props.autocomplete : "off"}
                        />
                      </>
                    </Tippy>
                  );
                } else {
                  return (
                    <Tippy disabled={(props.title?false:true)} content={ props?.title } >
                      <>                
                        <InputMask
                          mask={props?.mask}
                          maskChar={(props?.maskChar)}
                          className={style.input}
                          type={props.type === "date" ? "text" : (props.type === 'password' ? typeAux : props.type)}
                          name={props.name}
                          id={props.id}
                          placeholder={props.placeholder}
                          value={props.value ? props.value : ""}
                          onChange={props.onChange}
                          required={props.required === false ? false : true}
                          onFocus={(e) => handleSetFocus(e)}
                          onBlur={handleFocusOut}
                          onClick={props.onClick}
                          onKeyPress={props?.onKeyPress}
                          defaultChecked={props?.defaultChecked}
                          checked={props.checked}
                          autoComplete={props.autocomplete ? props.autocomplete : "off"}
                          onKeyUp={(e) => (props.type=="password" ? handleSecurity(e.target.value) : {})}
                          onKeyDown={props.onKeyDown}
                          style={props?.style}
                          maxLength={props?.maxLength}
                        ></InputMask>
                      </>
                    </Tippy>
                  );
                }
              })()}
        
              {(() => {
                if (
                  props.label &&
                  (props.type === "checkbox" || props.type === "radio")
                ) {
                  return <label htmlFor={props.id}>{props.label} {(props?.obs ? <span className="text-secondary">{props?.obs}</span> : '')}</label>;
                }
              })()}
        
              {(() => {
                if (props.icon) {
                  if(props.icon.toString().includes('[')){
                    return props.icon;
                  }else{
                    if (props.type !== "file" && props.type !== "image") {
                      return <Icon type={props.icon} readonly={props?.readonly} />;
                    }
                  }
                } else {
                  if(props.type === "date" && props?.icon !== false){
                    return <Icon type="calendar" animated title={false} style={{top:'-1px'}} readonly={props?.readonly} />;
                  }else if(props.type === "password") {
                    return <Icon type={'view'} animated title={viewPassword ? 'Ocultar' : 'Exibir'} active={viewPassword ? true : false} onClick={handleShowPassword} />
                  }else{
                    if (props.type === "file") {
                      return '';
                    }else if (props.type === "image") {
                      return '';
                    }
                  }
                }
              })()}
        
              {(() => {
                if (props.type === "password"){
                  return(
                    <div className={style.security + ' ' + (security?security=='strong'?style.strong:(security=='weak'?style.weak:style.median):'')}></div>
                  )
                }
              })()}
            </>
          )
        }
      })()}
    </div>
  );
}
