import { useEffect, useState, useContext } from "react";

import { calcDistance, cd, cdh, get_date } from "../../../../_assets/js/global";
import { JobsContext } from "../../../../context/Jobs";
import Tippy from "@tippyjs/react";
import { GlobalContext } from "../../../../context/Global";
import Icon from "../../../../components/body/icon";
import Form from "../../../../components/body/form";
import SelectReact from "../../../../components/body/select";
import Editar from "../../../../components/body/card/editar";
import Reabrir from "../../../../components/body/card/reabrir";
import Reprovar from "../../../../components/body/card/reprovar";
import Recusar from "../../../../components/body/card/recusar";
import Devolver from "../../../../components/body/card/devolver";
import Tutorial from "../../../../components/body/card/tutorial";
import axios from "axios";
import Cancelar from "../../../../components/body/card/cancelar";
import Card from "../../../../components/body/card";
import { toast } from "react-hot-toast";
import TrocaOperador from "../../../../components/body/card/trocaOperador";
import TrocaStatus from "../../../../components/body/card/trocaStatus";

// INTEGRAÇÕES
import Checklist from "../../../Checklist/Lista/cadastro";
import ChecklistLaravel from "../../../ChecklistLaravel/Lista/Cadastro";
import Telefones from '../../../Lojas/Lista';
import Respostas from "../../../Checklist/Lista/respostas";
import Check from "../../../../components/body/card/check";
import CheckOperador from "../../../../components/body/card/checkOperador";
import TrocaLoja from "../../../../components/body/card/trocaLoja";

export default function CardJobs(props) {
    // ESTADOS    
    const [operatorSelected, setOperatorSelected] = useState('');
    const [categoriaSelected, setCategoriaSelected] = useState('');

    // VARIÁVEIS
    var
        background,
        title,
        obs1,
        obs2,
        obs3,
        obs4,
        obs5,
        circle,
        job_system_integration_type,
        job_system_integration_type_aux,
        aux_job,
        job_status,
        distance = '',
        distance_aux = '',
        locationStore,
        nivel_msg,
        system_type,
        habilitarFinalizar,
        alertHour = false,
        obs6

    var system_integration = props?.card?.mov_sistemas;

    if (props?.card?.mov_sistemas) {
        var aux = JSON.parse(system_integration);
        system_type = aux.job_system_integration_type;
        var system_type_1 = aux.job_system_integration_type_1;
    } else {
        system_type = 0;
        var system_type_1 = 0;
    }

    // CONTEXT GLOBAL
    const { refreshCalendar, handleRefreshChat, filterModule, firstLoad } = useContext(GlobalContext);

    // CONTEXT JOBS
    const { optionsStatus, optionsLoja, configuracoes } = useContext(JobsContext);

    // CORES
    if (props.chamados) { // CHAMADOS
        if (!props?.card?.recebido || props?.card?.recebido == 0) {
            if (props?.card?.mov_urgente == 1) {
                background = 'orange';
            } else {
                background = 'blue';
            }
        } else {
            if (props?.card?.mov_urgente == 1) {
                background = 'orange';
            } else {
                background = '';
            }
        }

        if (props?.card?.status_supervisor === 2) {
            background = optionsStatus.filter((el) => {
                return el.status_name == 'atrasado';
            })[0]?.cor;
        }
    } else if (props.fases) { //FASES
        if ((props?.card?.status == 0 || props?.card?.status == 4) && props?.card?.data_job > get_date('datetime_sql', new Date())) { // PADRÃO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'nao_feito';
            })[0]?.cor;
        } else if ((props?.card?.status == 0 || props?.card?.status == 4) && props?.card?.data_job < get_date('datetime_sql', new Date()) && props?.card?.status_supervisor != 3) { // ATRASADO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'atrasado';
            })[0]?.cor;
        } else if (props?.card?.status == 0 && props?.card?.data_job < get_date('datetime_sql', new Date()) && props?.card?.status_supervisor == 3) { // ATRASADO REABERTO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'atrasado';
            })[0]?.cor;
        } else if (props?.card?.status == 1) { // CONCLUÍDO NO PRAZO SEM AVALIAÇÃO DO SUPERVISOR
            background = optionsStatus.filter((el) => {
                return el.status_name == 'feito';
            })[0]?.cor;
        } else if (props?.card?.status == 2) { // NÃO TEM
            background = optionsStatus.filter((el) => {
                return el.status_name == 'nao_tem';
            })[0]?.cor;
        } else if (props?.card?.status == 3) { // CONCLUÍDO COM ATRASO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'feito_com_atraso';
            })[0]?.cor;
        } else if (props?.card?.status == 5) { // CONCLUÍDO COM RESSALVA
            background = optionsStatus.filter((el) => {
                return el.status_name == 'feito_com_ressalva';
            })[0]?.cor;
        } else {
            background = '';
        }
    } else if (props.visitas) { // VISITAS
        if (props?.card?.status == 1) { // CONCLUÍDO NO PRAZO SEM AVALIAÇÃO DO SUPERVISOR
            background = optionsStatus.filter((el) => {
                return el.status_name == 'feito';
            })[0]?.cor;
        } else if (props?.card?.status == 2) { // NÃO TEM
            background = optionsStatus.filter((el) => {
                return el.status_name == 'nao_tem';
            })[0]?.cor;
        } else if (props?.card?.status == 3) { // CONCLUÍDO COM ATRASO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'feito_com_atraso';
            })[0]?.cor;
        } else if ((props?.card?.status == 0 || props?.card?.status == 4) && props?.card?.data_job < window.currentDateWithoutHour && props?.card?.status_supervisor != 3) { // ATRASADO
            background = optionsStatus.filter((el) => {
                return el.status_name == 'atrasado';
            })[0]?.cor;
        } else {
            background = '';
        }
    } else { // JOBS
        if (props?.tipoCalendario == 7 || props?.tipoCalendario == 9) { // FREQUÊNCIA
            background = '';
        } else if (props?.tipoCalendario == 3) { // PAINEL
            if ((props?.card?.qtd_feito + props?.card?.qtd_feito_com_inconformidade) == props?.card?.qtd_total && props?.card?.qtd_feito_com_atraso == 0 && props?.card?.qtd_atrasado == 0) {
                background = optionsStatus.filter((el) => {
                    return el.status_name == 'feito';
                })[0]?.cor;
            } else if (props?.card?.qtd_feito_com_atraso > 0 && props?.card?.qtd_atrasado == 0) {
                background = optionsStatus.filter((el) => {
                    return el.status_name == 'feito_com_atraso';
                })[0]?.cor;
            } else if (props?.card?.qtd_atrasado > 0) {
                background = optionsStatus.filter((el) => {
                    return el.status_name == 'atrasado';
                })[0]?.cor;
            } else {
                background = '';
            }
        } else {
            if (props?.card?.data_adiado) { // ADIADO
                background = optionsStatus.filter((el) => {
                    return el.status_name == 'adiado';
                })[0]?.cor;
            } else {
                if(props?.card?.substatus){
                    background = optionsStatus.filter((el) => {
                        return el.substatus == props?.card?.substatus;
                    })[0]?.cor_substatus;
                }else{
                    if (props?.card?.status_supervisor == 3) {
                        background = optionsStatus.filter((el) => {
                            return el.status_name == 'reaberto';
                        })[0]?.cor;
                    } else {
                        if ((props?.card?.status == 0 || props?.card?.status == 4) && props?.card?.data_job > get_date('datetime_sql', new Date()) && !props?.card?.id_apl.includes('224')) { // PADRÃO
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'nao_feito';
                            })[0]?.cor;
                        } else if ((props?.card?.status == 0 || props?.card?.status == 4) && props?.card?.data_job < get_date('datetime_sql', new Date()) && props?.card?.status_supervisor != 3 && !props?.card?.id_apl.includes('224')) { // ATRASADO
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'atrasado';
                            })[0]?.cor;
                        } else if (props?.card?.status == 0 && props?.card?.data_job < get_date('datetime_sql', new Date()) && props?.card?.status_supervisor == 3) { // ATRASADO REABERTO
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'atrasado';
                            })[0]?.cor;
                        } else if (props?.card?.status == 1) { // CONCLUÍDO NO PRAZO SEM AVALIAÇÃO DO SUPERVISOR
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'feito';
                            })[0]?.cor;
                        } else if (props?.card?.status == 2) { // NÃO TEM
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'nao_tem';
                            })[0]?.cor;
                        } else if (props?.card?.status == 3 && !props?.card?.id_apl.includes('224')) { // CONCLUÍDO COM ATRASO
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'feito_com_atraso';
                            })[0]?.cor;
                        } else if (props?.card?.status == 5) { // CONCLUÍDO COM RESSALVA
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'feito_com_ressalva';
                            })[0]?.cor;
                        } else if (props?.card?.status == 7) { // CONCLUÍDO COM INCONFORMIDADE
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'feito_com_inconformidade';
                            })[0]?.cor;
                        } else if (props?.card?.status == 6 && props?.card?.id_apl.includes('224')) { // CANCELADO (JOBS COM CHAMADOS)
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'atrasado';
                            })[0]?.cor;
                        } else if ((props?.card?.status == 1 || props?.card?.status == 3) && props?.card?.id_apl.includes('224')) { // CANCELADO (JOBS COM CHAMADOS)
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'feito';
                            })[0]?.cor;
                        } else if (props?.card?.status == 0 && props?.card?.id_apl.includes('224')) { // ATRASADO (JOBS COM CHAMADOS)
                            background = optionsStatus.filter((el) => {
                                return el.status_name == '';
                            })[0]?.cor;
                        } else if (props?.card?.status == 8) { // BLOQUEADO
                            background = optionsStatus.filter((el) => {
                                return el.status_name == 'bloqueado';
                            })[0]?.cor;
                        } else {
                            background = '';
                        }
                    }
                }
            }
        }
    }

    // DEFINE SE O BOTÃO DE FINALIZAR DO CARD DE GRUPO VAI FICAR HABILITADO OU NÃO CASO TENHA JOB INTERNO SEM FINALIZAR                                                      
    if (props?.card?.card_qtd_total > props?.card?.card_qtd_finalizado) {
        habilitarFinalizar = true
    } else {
        habilitarFinalizar = false;
    }

    // INTEGRAÇÃO CHECKLIST
    job_system_integration_type = props?.card?.mov_sistemas ? props?.card?.mov_sistemas : 0;

    job_system_integration_type_aux = JSON.parse(
        job_system_integration_type
    );

    job_system_integration_type = JSON.parse(
        job_system_integration_type
    );

    if (props?.card?.sistema_job_id === 1) {
        if (job_system_integration_type) {
            job_system_integration_type = `${props?.card?.sistema_job_id}.${job_system_integration_type.job_system_integration_type}`;
        }
    } else if (props?.card?.sistema_job_id === 18) {
        system_type = '{"sistema_aux":"' + job_system_integration_type.job_system_integration_type + '","sistema_aux_1":"' + job_system_integration_type.job_system_integration_type_1 + '","sistema_aux_2":"' + job_system_integration_type.job_system_integration_type_2 + '"}';
    }

    aux_job = props?.card?.id_job_status + "|||" + props?.card?.data_nao_formatada + "|||0";

    obs1 = '';
    obs2 = '';
    obs3 = '';
    obs4 = '';
    obs5 = '';
    circle = '';

    // OBSERVAÇÃO 1 (CANTO INFERIOR ESQUERDO, PRIMEIRA INFO)
    if (props.chamados) {
        obs1 = props?.card?.id_job_status;
    } else if (props.visitas) {
        obs1 = 'Data: ' + props?.card?.data_formatada.slice(0, 5);
    } else if (props.fases) {
        obs1 = props?.card?.data_formatada;
    } else {
        if (props?.tipoCalendario != 7) { // TIPO FREQUÊNCIA
            obs1 = props?.card?.frequencia + " " + (props?.card?.hora_limite_formatada ? props?.card?.hora_limite_formatada : '') + (props?.card?.data_adiado ? " (Adiado)" : "");
        }
    }

    // OBSERVAÇÃO 2 (CANTO INFERIOR ESQUERDO, SEGUNDA INFO)
    if (props.chamados) {
        obs2 = '';
    } else if (props.fases) {
        obs2 = '';
    } else if (props.visitas) {
        obs2 = (props?.card?.status == 1 || props?.card?.status == 3 ? 'Feito em: ' + props?.card?.dataHora_execucao_formatada.slice(0, 5) + props?.card?.dataHora_execucao_formatada.slice(10, 16) : '');
    } else {
        if (props?.tipoCalendario != 7) {
            if (props?.card?.status == 1 || props?.card?.status == 3) {
                obs2 = 'Feito em: ' + props?.card?.dataHora_execucao_formatada.slice(0, 5) + props?.card?.dataHora_execucao_formatada.slice(10, 16);
            } else if (props?.card?.status == 2) {
                obs2 = (props?.card?.motivo_select ? props?.card?.motivo_select : 'Não tem');
            } else {
                obs2 = '';
            }
        }
    }

    // OBSERVAÇÃO 3 (CANTO INFERIOR ESQUERDO, TERCEIRA INFO)
    if (props.chamados) {
        let dias = '';

        if (props?.card?.dias || props?.card?.dias == 0) {
            if (props?.card?.dias < 0) {
                dias = '(Agendado)';
            } else if (props?.card?.dias > 0) {
                dias = '(' + props?.card?.dias + ' dia' + (props?.card?.dias == 1 ? '' : 's') + ')';
            } else if (props?.card?.dias == 0) {
                dias = '(Hoje)';
            }
        }

        obs3 = props?.card?.data_inicio_formatada.slice(0, 5) + ' ' + dias;
    } else if (props.fases) {
        obs3 = '';
    } else if (props.visitas) {
        obs3 = '';
    } else {
        if (props?.tipoCalendario == 7) {
            if (props?.job.id_frequency == global.frequencia.unico) {
                obs3 = props?.card?.data_inicio_formatada
            } else if (props?.job.id_frequency == global.frequencia.semanal) {
                obs3 = get_date('day_name', get_date('date_sql', props?.card?.data_inicio_formatada, 'date'), 'date');
            } else if (props?.job.id_frequency == global.frequencia.mensal) {
                obs3 = 'Dia ' + props?.card?.data_inicio_formatada.slice(0, 2);
            } else if (props?.job.id_frequency == global.frequencia.anual) {
                obs3 = 'Dia ' + props?.card?.data_inicio_formatada.slice(0, 5);
            } else {
                obs3 = 'Início: ' + props?.card?.data_inicio_formatada;
            }
        } else {
            if (props?.card?.data_adiado) { // SE FOR CARD ADIADO NÃO EXIBE TEXTO
                obs3 = '';
            } else {
                if (props?.card?.status_supervisor == 1) {
                    obs3 = 'Aprovado';
                } else if (props?.card?.status_supervisor == 2) {
                    obs3 = 'Reprovado';
                } else if (props?.card?.status_supervisor == 3) {
                    obs3 = 'Reaberto';
                } else {
                    obs3 = '';
                }
            }
        }
    }

    // OBSERVAÇÃO 4 (CANTO SUPERIOR DIREITO)
    if (props.chamados) {
        obs4 = '';
    } else if (props.fases) {
        obs4 = '';
    } else if (props.visitas) {
        if (job_system_integration_type_aux.job_system_integration_type_1) {
            // CALCULA DISTÂNCIA
            locationStore = optionsLoja.filter((element) => element.value == job_system_integration_type_aux.job_system_integration_type_1);
            if (locationStore.length > 0 && locationStore[0].localizacao && props?.card?.localizacao_realizado) {
                let coordenada = locationStore[0].localizacao.split(",");
                let coordenada_realizado = props?.card?.localizacao_realizado.split(",");
                distance = calcDistance(coordenada[0], coordenada[1], coordenada_realizado[0], coordenada_realizado[1]);

                if (distance !== '') {
                    if (distance < 1) { // SE FOR MAIOR QUE 1KM
                        distance = distance * 1000;
                        obs4 = Math.round(distance) + 'm';
                    } else {
                        obs4 = Math.round(distance) + 'km';
                    }
                }
            } else {
                obs4 = '';
            }
        }
    } else {
        if (props?.card?.status == 8) {
            obs4 = <Icon type="lock" animated />;
        } else {
            if (props?.card?.job_arquivado) {
                obs4 = '(Arquivado)';
            } else {
                obs4 = '';
            }
        }
    }

    // ALERTA DE JOB EXPIRANDO (CALENDÁRIO JOBS)
    alertHour = false;
    if (!props.chamados && !props.fases && !props.visitas) {
        if (props?.tipoCalendario == 1 || props?.tipoCalendario == 2 || props?.tipoCalendario == 4) { // SE FOR CALENDÁRIO (1), LOJA (2) OU USUÁRIO (4)
            if (props?.card?.status == 0 && (props?.card?.id_usuario_job == window.rs_id_usr || props?.card?.id_loja == window.rs_id_lja)) {
                if (props?.card?.data_nao_formatada == window.currentDateWithoutHour && props?.card?.status_supervisor != 3) {
                    if (props?.card?.hora_limite_formatada > (window.currentHour + ':' + window.currentMinutes)) {
                        let endDate = new Date(props?.card?.data_job);
                        let differenceValue = (new Date().getTime() - endDate.getTime()) / 1000;
                        differenceValue /= 60;
                        let result = Math.abs(Math.round(differenceValue))

                        if (result < 30) {
                            obs4 = 'Vence em breve';
                            alertHour = true;
                        } else {
                            alertHour = false;
                        }
                    }
                }
            }
        }
    }

    // DEFINE VALOR DA OBS5
    if (props?.card?.id_apl.includes('224') && !props?.chamados && !props.fases && !props.visitas) {
        if (props?.tipoCalendario == 1) {
            if (props?.card?.status == 6) {
                obs5 = 'Cancelado';
            } else {
                if (props?.card?.cad_usr == props?.usuario || props?.card?.cad_lja == props?.loja) {
                    obs5 = 'Solicitado'
                }
            }
        } else if (props?.tipoCalendario == 2) {
            if (props?.card?.status == 6) {
                obs5 = 'Cancelado';
            } else {
                if (props?.card?.cad_lja == props?.job?.id_lja) {
                    obs5 = 'Solicitado'
                }
            }
        } else if (props?.tipoCalendario == 4) {
            if (props?.card?.status == 6) {
                obs5 = 'Cancelado';
            } else {
                if (props?.card?.cad_usr == props?.job?.id_usr) {
                    obs5 = 'Solicitado'
                }
            }
        }
    }

    // DEFINE CIRCLE (CIRCULO COM SIGLA DO DEPARTAMENTO)
    if (!props.chamados && !props.fases && !props.visitas) {
        if (props?.card?.departamento_usr_cad || props?.card?.modulo) {
            if (props?.card?.id_apl) {
                if (props?.card?.id_apl.includes('224')) {
                    circle = (props?.card?.departamento_usr_cad ? props?.card?.departamento_usr_cad : props?.card?.modulo)
                }
            }
        }
    }

    // DEFINE TITULOS
    if (configuracoes.length > 0 && configuracoes[0]?.conf_titulo_tipo && configuracoes[0]?.id_apl == window.rs_id_apl) {
        title = '';

        if (configuracoes[0]?.conf_titulo_tipo.includes('empreendimento') && props?.card?.empreendimento) {
            title += props?.card?.empreendimento;
        }

        if (configuracoes[0]?.conf_titulo_tipo.includes('loja')) {
            if (configuracoes[0]?.conf_titulo_tipo.includes('empreendimento')) {
                title += ' - ' + props?.card?.cad_lja_nome;
            } else {
                if (props?.card?.cad_lja_nome) {
                    title += props?.card?.cad_lja_nome;
                } else {
                    title += props?.card?.cad_usr_nome;
                }
            }
        } else {
            if (!configuracoes[0]?.conf_titulo_tipo) {
                title += props?.card?.job;
            }
        }
    } else {
        if (props?.card?.cliente) {
            title = props?.card?.cliente + ' - ' + props?.card?.job;
        } else {
            title = props?.card?.job;
        }
    }


    // STATUS JOBS
    if (props?.card?.status == 1) {
        job_status = "Concluído";
    } else if (props?.card?.status == 2) {
        job_status = "";
    } else if (props?.card?.status == 3) {
        job_status = "ComAtraso";
    } else if (props?.card?.status == 3) {
        job_status = "Adiado";
    } else {
        job_status = "";
    }

    // NÍVEL MENSAGENS CHAT (CHAMADOS)
    if (props?.job.tipo_fase == 'Operação' && props.chamados) {
        nivel_msg = 2;
    } else if (props?.job.tipo_fase == 'Check') {
        nivel_msg = 1;
    } else {
        nivel_msg = '';
    }

    // DEFINE SUBTITULO
    let subtitle = '';
    if (props.fases) { // SE ESTIVER NO SISTEMA FASES
        if (props?.card?.id_grupo_mov) {
            subtitle += props?.card?.job;
        } else {
            if (props?.card?.categoria) {
                subtitle += props?.card?.categoria + " / " + props?.card?.subcategoria;
            }
        }
    } else {
        subtitle += props?.card?.categoria + " / " + props?.card?.subcategoria;
    }

    // HANDLE SET STATUS IFRAME
    const handleSetStatusIframe = () => {
        props.statusIframe((props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1));
    }

    // REFESH CARD
    const handleRefreshCard = (e) => {
        props.refreshCard(e);
    }

    //FINALIZA O JOB COM IFRAME
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event?.data?.iframe_name) {
                if ((
                    event.origin === "http://madnezz.test" ||
                    event.origin === "http://localhost:3000" ||
                    event.origin === "http://localhost:3001" ||
                    event.origin === "http://localhost:8000" ||
                    event.origin === "https://local.madnezz.com.br" ||
                    event.origin === process.env.REACT_APP_URL_DEV ||
                    event.origin === process.env.REACT_APP_URL_PROD
                ) && event?.data?.iframe_name.split('_')[2] == props?.card?.id_job_status) {
                    if (event.data.function_type === "iframeFinishJob") {
                        changeStatus("Finalizou o job", props?.card?.id_job, props?.card?.id_job_status, (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1), undefined, event.data.iframe_data, undefined, undefined, undefined, undefined, undefined, props?.card?.mov_prioridade);
                        props.changeStatus({
                            status: (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1),
                            id_group: undefined,
                            id_job_status_parent: undefined,
                            id_job_status: (event.data.iframe_name.split('_')[2] ? Number(event.data.iframe_name.split('_')[2]) : ''),
                        });
                    }
                }
            }
        });
    }, []);

    // FINALIZA JOB DE API
    const finalizaJob = (id_job, id_job_status, status, data, prioridade) => {
        changeStatus("Finalizou o job", id_job, id_job_status, status, undefined, data, 'next', 'Operação', undefined, undefined, undefined, prioridade);
        props.changeStatus({
            status: status,
            id_group: undefined,
            id_job_status_parent: undefined,
            id_job_status: id_job_status
        });
    }

    // Aqui 
    const [autoSubmit, setAutoSubmit] = useState(false);
    // FUNÇÃO PARA TROCA DE STATUS DOS CARDS
    function changeStatus(ativ_desc, id_job, id_job_status, status, msg = undefined, data_aux = undefined, tipo = undefined, tipo_fase = undefined, id_group = undefined, id_job_status_parent = undefined, nivel_msg = 0, prioridade = undefined) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_status&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                status: status,
                msg: msg,
                data_aux: data_aux,
                tipo: tipo,
                tipo_fase: tipo_fase,
                finaliza_job: data_aux,
                nivel_msg: nivel_msg,
                location: (global.allowLocation ? (global.lat2 + ',' + global.lon2) : undefined),
                priority: prioridade
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            // props.callbackAutoFinish();
            props.changeStatus({
                status: status,
                id_group: id_group,
                id_job_status_parent: id_job_status_parent,
                id_job_status: id_job_status,
                cod_status: response?.data?.cod_status
            });
            // props.chamados && setAutoSubmit(true)
        });
    }

    // FINALIZA CARD AUTOMATICAMENTE
    useEffect(() => {
        if (props?.autoFinish == props?.card?.lote && props?.card?.status == 0) {
            changeStatus(
                "Finalizou o card",
                props?.card?.id_job,
                props?.card?.id_job_status,
                1,
                undefined,
                undefined,
                undefined,
                props?.job.tipo_fase
            );
        }
    }, [props?.autoFinish]);

    // FUNÇÃO PARA TROCA DE STATUS SUPERVISOR DOS CARDS
    function changeStatusSupervisor(ativ_desc, id_job, id_job_status, status, msg = undefined, data_aux = undefined, tipo = undefined, tipo_fase = undefined) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_statusSupervisor&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                status: status,
                msg: msg,
                data_aux: data_aux,
                tipo: tipo,
                tipo_fase: tipo_fase
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            if (props.chamados || props.visitas) { // SE FOR CHAMADOS OU VISITAS ONDE É NECESSÁRIO ATUALIZAR TODA A FILA
                refreshCalendar();
            } else {
                props.refreshCard(id_job_status);
            }

            // handleRefreshChat(true);
        });
    }

    // FUNÇÃO PARA ADIAR
    function setDate(ativ_desc, id_job_status, date) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_status&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_mov: id_job_status,
                status: 4,
                postpone_date: cd(date),
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            if (!firstLoad) {
                refreshCalendar(false, true);
            }
            // handleRefreshChat(true);
        });
    }

    // TROCA DE OPERADOR
    function changeOperator(id_job, id_job_status, id_operator, name_operator, tipo_fase, ativ_desc) {
        axios({
            method: 'post',
            url: window.host_madnezz + '/systems/integration-react/api/list.php?do=set_operator&filter_id_module=' + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                id_job: id_job,
                id_mov: id_job_status,
                id_operador: id_operator,
                tipo_fase: tipo_fase,
                ativ_desc: ativ_desc + ' ' + name_operator,
                tipo: (tipo_fase == 'Check' ? 'present' : 'next'),
                filter_subtype: props?.subTipoCalendario
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            toast('Chamado encaminhado ao operador ' + name_operator);
            if (!firstLoad) {
                refreshCalendar();
            }
            // handleRefreshChat(true);
        });
    }

    // TROCA DE CATEGORIA (CHAMADOS)
    function changeCategoria(id_job, id_job_status, id_categoria, ativ_desc, tipo_fase = undefined) {
        axios({
            method: 'post',
            url: window.host_madnezz + '/systems/integration-react/api/list.php?do=set_module&filter_id_module=' + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                id_job: id_job,
                id_mov: id_job_status,
                id_modulo: id_categoria,
                ativ_desc: ativ_desc,
                tipo_fase: tipo_fase
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            toast('Categoria alterada');
            if (!firstLoad) {
                refreshCalendar();
            }
            // handleRefreshChat(true);
        });
    }

    // FUNÇÃO PARA MARCAR EM EXECUÇÃO
    function changeExecuting(ativ_desc, id_job_status, status) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_execution&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_mov: id_job_status,
                status: status,
                nivel_msg: 2
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            toast('Novo chamado em execução');
            if (!firstLoad) {
                refreshCalendar();
            }
            // handleRefreshChat(true);
        });
    }

    // FUNÇÃO PARA VOLTAR/AVANÇAR DE FASE
    function changePhase(ativ_desc, id_job, id_job_status, type, tipo_fase, lote = undefined, alert = undefined, set_msg = true) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_phase&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                lote: lote,
                tipo: type,
                tipo_fase: tipo_fase,
                set_msg: set_msg
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            if (alert !== false) {
                toast('Fase alterada');
            }
            if (!firstLoad) {
                refreshCalendar();
            }
            // handleRefreshChat(true);
        });
    }

    // AVALIAÇÃO
    function setRate(ativ_desc, id_job, id_job_status, id_avaliacao) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_assessment&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                id_avaliacao: id_avaliacao,
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            if (!firstLoad) {
                refreshCalendar();
            }
            // handleRefreshChat(true);
            toast('Chamado avaliado com sucesso');
        });
    }

    // FUNÇÃO PARA RECEBER CHAMADO
    function changeReceived(ativ_desc, id_job, id_job_status) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_received&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                status_received: 1,
                nivel_msg: 0
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            props.refreshCard(id_job_status);
            // handleRefreshChat(true);
        });
    }

    // FUNÇÃO AO TROCAR OPERADOR
    const handleChangeOperator = () => {
        if (!firstLoad) {
            refreshCalendar();
        }
    }

    // FUNÇÃO PARA ARQUIVAR
    function handleArchive(id_job, id_job_status, status) {
        if(window.confirm('Deseja ' + (status === 1 ? 'arquivar' : 'desarquivar') + ' esse card?')){
            axios({
                method: 'post',
                url: window.host_madnezz + '/systems/integration-react/api/list.php?do=closedJob',
                data: {
                    id_job: id_job,
                    id_mov: id_job_status,
                    ativ_desc: 'Arquivou o card',
                    closed: status
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                toast("Card arquivado com sucesso!");
                refreshCalendar();
            })
        }
    }

    // DEFINE SE CARD FICA NO FORMATO INATIVO
    let disabled = false;
    if (props.chamados) {
        if (props?.card?.dias < 0) {
            disabled = true;
        } else {
            disabled = false;
        }
    } else if (!props.chamados && !props.fases && !props.visitas) {
        if (props?.card?.job_arquivado) {
            disabled = true;
        }
    } else {
        disabled = false;
    }

    let troca_operador = false;
    let troca_status = false;

    // SE RECEBER A PROPS DE TROCA_OPERADOR COM FALSE
    if (props?.troca_operador === false || props?.troca_status === false) {
        if (props?.troca_operador === false) {
            troca_operador = false;
        }

        if (props?.troca_status === false) {
            troca_status = false;
        }
    } else {
        if (props?.tipoCalendario == 9) {
            troca_operador = false;
            troca_status = false;
        } else {
            if (props?.card?.frequencia_id == 143) { // SE A FREQUÊNCIA FOR "ÚNICO"

                // SE FOR DO SISTEMA CHAMADOS
                if (props.chamados) {

                    // SE O CARD NÃO FOR AGENDADO E NÃO ESTIVER NA FASE DE PÓS-VENDA
                    if (props?.card?.dias >= 0 && props?.job.tipo_fase !== 'Pós-venda') {
                        troca_operador = true;
                        troca_status = true;
                    } else {
                        troca_operador = false;
                        troca_status = false;
                    }

                    // SE A PERMISSÃO FOR MAIS QUE 2 OU FOR 2 E ESTIVER NA FASE DE CHECK
                    if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') || ((window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker') && props?.job.tipo_fase == 'Check')) {
                        troca_operador = true;
                        troca_status = true;
                    } else {
                        troca_operador = false;
                        troca_status = false;
                    }
                } else if(props.fases){
                    // SE O STATUS DO CARD FOR 0 E NÃO SER USUÁRIO LOJISTA
                    if (props?.card?.status == 0 && window.rs_id_lja == 0 || !window.rs_id_lja) {
                        troca_operador = true;
                        troca_status = true;
                    } else {
                        troca_operador = false;
                        troca_status = false;
                    }
                } else {
                    troca_operador = false;
                    troca_status = false;
                }
            } else {
                troca_operador = false;
                troca_status = false;
            }
        }
    }

    // CALLBACK AO CLICAR EM EXPANDIR O CARD
    const handleExpandCallback = (e) => {
        if (props?.expand) {
            props?.expand?.callback(e)
        }
    }

    // CALLBACK DE RELOAD APÓS EDITAR
    const handleReload = () => {
        if (props?.reload) {
            props.reload();
        }
    }

    return (
        props?.changeLayout ? (
            <Tippy
                content={
                    <div className="text-center">
                        {(props?.subTipoCalendario === 'user' ? props?.card?.usuario : props?.card?.loja)}<br />
                        Porc. Feitos:
                        {" "}
                        {(((Number(props?.card?.qtd_feito) + Number(props?.card?.qtd_feito_com_atraso)) * 100) / Number(props?.card?.qtd_total)).toFixed(0)}%<br /><br />

                        Jobs: {props?.card?.qtd_total}<br />
                        Feitos: {props?.card?.qtd_feito}<br />
                        Feitos com atraso: {props?.card?.qtd_feito_com_atraso}{" "}<br />
                        Feitos com inconformidade: {props?.card?.qtd_feito_com_inconformidade}{" "}<br />
                        Feitos com ressalva: {props?.card?.qtd_feito_com_ressalva}{" "}<br />
                        Não feitos: {props?.card?.qtd_nao_feito}<br />
                        Não tem: {props?.card?.qtd_nao_tem}<br />
                        Adiados: {props?.card?.qtd_adiado}<br />
                        Atrasados: {props?.card?.qtd_atrasado}<br />
                        Cancelados: {props?.card?.qtd_cancelado}<br />
                    </div>
                }
            >
                <div>
                    <a href={window.host + '/systems/' + window.link + '/?page=calendario&tipo=1&id_lja=' + props?.card?.id_loja + '&periodStart=' + cd(props?.periodStart).replaceAll('/', '-') + '&periodEnd=' + cd(props?.periodEnd).replaceAll('/', '-')} target="_blank">
                        <Card
                            id={props?.card?.id_job}
                            widget={props?.widget}
                            title={(props?.subTipoCalendario === 'user' ? props?.card?.usuario : props?.card?.loja)}
                            subtitle={
                                (
                                    ((Number(props?.card?.qtd_feito) +
                                        Number(props?.card?.qtd_feito_com_atraso)) *
                                        100) /
                                    Number(props?.card?.qtd_total)
                                ).toFixed(0) + "%"
                            }
                            background={background}
                            size="smallest"
                        ></Card>
                    </a>
                </div>
            </Tippy>
        ) : (
            <Card
                // ref={props?.card?.id_job_status}
                autoSubmit={autoSubmit}
                setAutoSubmit={setAutoSubmit}
                refreshCard={handleRefreshCard}
                position={{
                    enabled: (props.chamados && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && props?.job.tipo_fase == 'Operação' && (filterModule == 2361 && window.rs_id_emp == 26 || filterModule != 2361) ? true : false),
                    group: props?.group.cards,
                    position: (props?.i + 1)
                }}
                expand={{
                    enabled: true,
                    index: props?.indexSlide,
                    callback: handleExpandCallback
                }}
                widget={props?.widget}
                parent={props?.card}
                parents={props?.job}
                id={'card_' + props?.card?.id_job_status}
                sortable={false}
                id_aux={props?.card?.id_job_status}
                id_group={props?.card?.id_job}
                focus={(props.chamados && props?.card?.job_execucao == 1 ? true : false)}
                onClick={handleSetStatusIframe}
                title={title}
                tippy={title}
                filters={{
                    filter_type: props?.tipoCalendario
                }}
                aux_form={props?.card?.aux_form}
                swiper={props?.swiper}
                onSetDateInternal={setDate}
                subtitle={subtitle}
                view={(props?.tipoCalendario == 7 ? false : true)}
                obs1={obs1}
                obs2={obs2}
                obs3={obs3}
                obs4={obs4}
                obs5={obs5}
                circle={circle}
                attention={(props?.card?.status_ant == 3 ? 'Atenção, job frequentemente feito em atraso' : false)}
                visualized={{
                    show: (props?.card?.id_apl.includes('229') && props?.tipoCalendario != 7 ? true : false),
                    confirm: props?.card?.visualizado,
                    date: props?.card?.data_visualizado
                }}
                condition={(props?.visitas || props?.tipoCalendario == 7 ? true : false)}
                internal={
                    (props.chamados && props?.card?.dias < 0 ? false : {
                        new: (props?.job.tipo_fase == 'Check' || props?.card?.status == 1 || props?.card?.status == 2 ? false : true),
                        params: {
                            options: props?.optionsOperator,
                            ativ_desc: 'Trocou de operador para',
                            filterModule: (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
                            filter_subtype: props?.subTipoCalendario
                        }
                    })
                }
                reload={{
                    internal: props?.reloadInternal
                }}
                callback={(e) => (e === true ? handleRefreshCard(props?.card?.id_job_status) : {})}
                chat={
                    (props?.tipoCalendario == 9 ?
                        false
                        :
                        {
                            api: window.host_madnezz + '/systems/integration-react/api/list.php?do=set_msg&filter_id_module=' + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
                            anexo: (props?.card?.desabilitar?.split(',').includes('3') ? false : true),
                            data: {
                                nivel_msg: nivel_msg
                            },
                            id: props?.card?.id_job_status,
                            defaultMessage: {
                                date: (props?.card?.cad_data ? cdh(props?.card?.cad_data) : ''),
                                sender: props?.card?.cad_usr_nome,
                                text: 'Abriu o ' + (props.chamados ? 'chamado' : 'job'),
                                align: (props?.card?.cad_usr == window.rs_id_usr ? 'right' : 'left')
                            },
                            send: (() => { // CHECA SE O ENVIO DE MENSAGENS DEVE OU NÃO SER HABILITADO
                                if (props?.card?.desabilitar?.split(',').includes('5')) {
                                    return false;
                                } else {
                                    if (props.chamados) { // SE ESTIVER EM CHAMADOS
                                        if (props?.card?.dias < 0) {
                                            return false
                                        } else {
                                            if (props?.job.tipo_fase != 'Pós-venda' && (window.rs_id_lja == 0 || !window.rs_id_lja) && (filterModule == 2361 && window.rs_id_emp == 26 || filterModule != 2361)) {
                                                if (props?.job.tipo_fase == 'Check') { // SE ESTIVER NA FASE DE CHECK
                                                    if (props?.card?.id_usr_sup) { // SE ESTIVER NA FILA DE UM OPERADOR NO CHECK
                                                        if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') { // SE A PERMISSÃO FOR MENOR QUE "CHECKER"
                                                            return false;
                                                        } else {
                                                            if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                return false;
                                                            } else {
                                                                if (props?.card?.id_usuario == window.rs_id_usr) {
                                                                    return true;
                                                                } else {
                                                                    return false;
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        return false;
                                                    }
                                                } else if (props?.job.tipo_fase == 'Início') {
                                                    if (!props?.card?.recebido || props?.card?.recebido == 0) {
                                                        if (window.rs_permission_apl === 'leitura') {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    } else {
                                                        if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') {
                                                            return false;
                                                        } else {
                                                            if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                return false;
                                                            } else {
                                                                if (window.rs_permission_apl === 'leitura') {
                                                                    return false;
                                                                } else {
                                                                    return true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                        return false;
                                                    } else {
                                                        if (props?.card?.id_usuario == window.rs_id_usr) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    }
                                                }
                                            } else {
                                                if (props?.job.tipo_fase == 'Início') {
                                                    if (!props?.card?.recebido || props?.card?.recebido == 0) {
                                                        return true;
                                                    } else {
                                                        if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') {
                                                            return false;
                                                        } else {
                                                            if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                        return false;
                                                    } else {
                                                        if (props?.job.tipo_fase == 'Pós-venda') {
                                                            return false;
                                                        } else {
                                                            if (window.rs_id_lja > 0) {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        if (props?.card?.data_adiado) {
                                            return false;
                                        } else {
                                            if (props?.card?.status == 1 || props?.card?.status == 2 || props?.card?.status == 3) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            })()
                        }
                    )
                }
                actions={
                    <>
                        {(() => {
                            if (props?.job.tipo_fase == 'Início' && props?.card?.dias >= 0 && props.chamados && (!props?.card?.recebido || props?.card?.recebido == 0)) {
                                if (props?.card?.cad_usr == window.rs_id_usr) { // SE A PERMISSÃO FOR MENOR/IGUAL A CHECKER E CAD USR SER IGUAL AO LOGADO OU PERMISSÃO MAIOR QUE CHECKER       
                                    return (
                                        <>
                                            <Cancelar
                                                title={'Cancelar chamado'}
                                                id_job={props?.card?.id_job}
                                                id_job_status={props?.card?.id_job_status}
                                                job={props?.card?.job}
                                                chamados={props.chamados}
                                                callback={() => refreshCalendar()}
                                            />
                                        </>
                                    )
                                }

                                if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') && (window.rs_id_lja == 0 || !window.rs_id_lja) && props?.card?.cad_usr != window.rs_id_usr) {
                                    return (
                                        <>
                                            <Reprovar
                                                title={'Reprovar chamado'}
                                                id_job={props?.card?.id_job}
                                                id_job_status={props?.card?.id_job_status}
                                                job={props?.card?.job}
                                                chamados={props.chamados}
                                                tipo="present"
                                                tipo_fase="Pós-venda"
                                                callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                            />
                                        </>
                                    )
                                }
                            }
                        })()}

                        {(() => {
                            if (props?.job.tipo_fase == 'Check' && props?.card?.id_usr_sup && props?.tipoCalendario != 7) {
                                if (props?.card?.id_usr_sup == window.rs_id_usr) {
                                    return (
                                        <>
                                            <Devolver
                                                id_job={props?.card?.id_job}
                                                id_job_status={props?.card?.id_job_status}
                                                chamados={props.chamados}
                                                callback={() => refreshCalendar()}
                                            />

                                            {(props?.card?.desabilitar?.split(',').includes('8') ?
                                                <></>
                                                :
                                                <Check
                                                    id_job={props?.card?.id_job}
                                                    id_job_status={props?.card?.id_job_status}
                                                    chamados={props.chamados}
                                                    modules={props?.optionsModule}
                                                    status={2} // REPROVAR
                                                    tipo="next"
                                                    card={props?.card}
                                                    callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                                />
                                            )}

                                            {(props?.card?.desabilitar?.split(',').includes('7') ?
                                                <></>
                                                :
                                                <Check
                                                    id_job={props?.card?.id_job}
                                                    id_job_status={props?.card?.id_job_status}
                                                    chamados={props.chamados}
                                                    modules={props?.optionsModule}
                                                    status={1} // APROVAR
                                                    tipo="next"
                                                    card={props?.card}
                                                    callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                                />
                                            )}
                                        </>
                                    )
                                }
                            }

                            // JOB FINALIZADO PELO OPERADOR, AINDA SEM AVALIAÇÃO DO SUPERVISOR
                            if ((props?.card?.status == 1 || props?.card?.status == 2 || props?.card?.status == 3 || props?.card?.status == 7) && (!props?.card?.status_supervisor && props?.card?.tipo_fase !== 'Pós-venda') && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master')) {
                                return (
                                    <>
                                        {props?.card?.mov_url_video ? (
                                            <Tutorial url={props?.card?.mov_url_video} title={props?.card?.job} />
                                        ) : (
                                            ""
                                        )}

                                        {(!props.chamados && !props?.card?.data_adiado && !props.visitas ? (!window.rs_id_lja || window.rs_id_lja == 0) && window.rs_permission_apl === 'master' && ((!props?.card?.status_supervisor || props?.card?.status_supervisor == 3)) ?
                                            (
                                                <>
                                                    <Editar
                                                        id={props?.card?.id_job}
                                                        id_emp={props?.job?.id_emp}
                                                        lote={props?.card?.lote}
                                                        disabled={(props?.card?.status == 0 || props?.card?.status == 4 || props?.tipoCalendario == 7 ? (/*props?.card?.card_qtd_total > 0*/ false ? true : false) : true)}
                                                        /*disabledTitle={(props?.card?.card_qtd_total > 0 ? 'Não é possível editar cards com jobs internos' : '')}*/
                                                        chamados={props.chamados}
                                                        fases={props.fases}
                                                        visitas={props.visitas}
                                                        aux_form={props?.card?.aux_form}
                                                        model={(props?.tipoCalendario == 9 ? { edit: true } : false)}
                                                        reload={handleReload}
                                                    />

                                                    {(props?.tipoCalendario == 9 ?
                                                        <Editar
                                                            id={props?.card?.id_job}
                                                            id_emp={props?.job?.id_emp}
                                                            lote={props?.card?.lote}
                                                            disabled={(props?.card?.status == 0 || props?.card?.status == 4 || props?.tipoCalendario == 7 ? (/*props?.card?.card_qtd_total > 0*/ false ? true : false) : true)}
                                                            chamados={props.chamados}
                                                            fases={props.fases}
                                                            visitas={props.visitas}
                                                            aux_form={props?.card?.aux_form}
                                                            icon={'clone'}
                                                            iconTitle={'Copiar'}
                                                            model={{ edit: false }}
                                                            reload={handleReload}
                                                        />
                                                        : '')}
                                                </>
                                            ) : (
                                                ""
                                            )
                                            : '')}

                                        {(!props.chamados && !props?.card?.data_adiado && !props.fases && !props.visitas && props?.tipoCalendario != 7 ?
                                            <>
                                                {(props?.card?.desabilitar?.split(',').includes('9') ?
                                                    <></>
                                                    :
                                                    <Reabrir
                                                        id_job={props?.card?.id_job}
                                                        id_job_status={props?.card?.id_job_status}
                                                        id_modulo={props?.card?.id_modulo}
                                                        chamados={props.chamados}
                                                        callback={(e) => handleRefreshCard(e)}
                                                    />
                                                )}

                                                {(props?.card?.desabilitar?.split(',').includes('8') ?
                                                    <></>
                                                    :
                                                    <Reprovar
                                                        id_job={props?.card?.id_job}
                                                        id_job_status={props?.card?.id_job_status}
                                                        job={props?.card?.job}
                                                        id_lja={(locationStore ? (locationStore.length > 0 ? locationStore[0].id : '') : '')}
                                                        id_usr={props?.card?.id_usr}
                                                        chamados={props.chamados}
                                                        callback={(e) => handleRefreshCard(e)}
                                                    />
                                                )}

                                                {(props?.card?.desabilitar?.split(',').includes('7') ?
                                                    <></>
                                                    :
                                                    <Icon
                                                        type="check"
                                                        title="Aprovar"
                                                        animated
                                                        onClick={() =>
                                                            changeStatusSupervisor(
                                                                "Aprovou o job",
                                                                props?.card?.id_job,
                                                                props?.card?.id_job_status,
                                                                1,
                                                                undefined,
                                                                undefined,
                                                                'next',
                                                                props?.job.tipo_fase
                                                            )
                                                        }
                                                    />
                                                )}
                                            </>
                                            : '')}
                                    </>
                                );
                            }

                            // CARD EM PÓS-VENDA
                            if ((props?.card?.status == 0 || props?.card?.status == 1 || props?.card?.status == 2 || props?.card?.status == 3 || props?.card?.status == 6)) {
                                if ((!props?.fases && !props?.visitas) && props?.job.tipo_fase == 'Pós-venda' && props?.card?.cad_usr == window.rs_id_usr) {
                                    return (
                                        <>
                                            {props.iconAvaliacao.map((item, i) => {
                                                var iconColor = '';

                                                if (props?.card?.avaliacao) {
                                                    if (props?.card?.id_avaliacao == 2376 && props?.card?.id_avaliacao == item.value) {
                                                        iconColor = 'text-success';
                                                    } else if (props?.card?.id_avaliacao == 2377 && props?.card?.id_avaliacao == item.value) {
                                                        iconColor = 'text-warning';
                                                    } else if (props?.card?.id_avaliacao == 2378 && props?.card?.id_avaliacao == item.value) {
                                                        iconColor = 'text-danger';
                                                    }
                                                }

                                                return (
                                                    <Icon
                                                        key={'icon_' + i}
                                                        type={item.aux}
                                                        title={item.label}
                                                        onClick={() => setRate('Avaliou o chamado como ' + item.label, props?.card?.id_job, props?.card?.id_job_status, item.value)}
                                                        disabled={(props?.card?.id_avaliacao ? true : false)}
                                                        color={iconColor}
                                                        className={iconColor}
                                                        animated
                                                    />
                                                )
                                            })}

                                            {(props?.card?.desabilitar?.split(',').includes('8') ?
                                                <></>
                                                :
                                                <Reabrir
                                                    id_job={props?.card?.id_job}
                                                    id_job_status={props?.card?.id_job_status}
                                                    id_modulo={props?.card?.id_modulo}
                                                    chamados={true}
                                                    disabled={(props?.card?.id_avaliacao ? true : false)}
                                                    callback={() => refreshCalendar()}
                                                />
                                            )}
                                        </>
                                    )
                                }
                            }

                            // AÇÕES DA FILA "CHECK"
                            if (props?.job.tipo_fase == 'Check' && props?.card?.id_usr_sup && props?.tipoCalendario != 7) {
                                if (props?.card?.id_usr_sup == window.rs_id_usr) {
                                    return (
                                        <>
                                            <Devolver
                                                id_job={props?.card?.id_job}
                                                id_job_status={props?.card?.id_job_status}
                                                chamados={props.chamados}
                                                callback={() => refreshCalendar()}
                                            />
                                            {(props?.card?.desabilitar?.split(',').includes('8') ?
                                                <></>
                                                :
                                                <Icon
                                                    type="reprovar"
                                                    title="Reprovar"
                                                    animated
                                                    onClick={() =>
                                                        changeStatusSupervisor(
                                                            "Reprovou o job",
                                                            props?.card?.id_job,
                                                            props?.card?.id_job_status,
                                                            2,
                                                            undefined,
                                                            undefined,
                                                            'next',
                                                            props?.job.tipo_fase
                                                        )
                                                    }
                                                />
                                            )}

                                            {(props?.card?.desabilitar?.split(',').includes('7') ?
                                                <></>
                                                :
                                                <Icon
                                                    type="check"
                                                    title="Aprovar"
                                                    animated
                                                    onClick={() =>
                                                        changeStatusSupervisor(
                                                            "Aprovou o job",
                                                            props?.card?.id_job,
                                                            props?.card?.id_job_status,
                                                            1,
                                                            undefined,
                                                            undefined,
                                                            'next',
                                                            props?.job.tipo_fase
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    )
                                }
                            } else {
                                if ((props?.card?.status == 0 || props?.card?.status == 4 || props?.card?.status == 5) || props.fases) {
                                    return (
                                        <>
                                            {(() => {
                                                if (props.chamados && props?.indexSlide == 0 && props?.card?.dias >= 0 && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') && (!props?.card?.recebido || props?.card?.recebido == 0) && (window.rs_id_lja == 0 || !window.rs_id_lja) && (filterModule == 2361 && window.rs_id_emp == 26 || filterModule != 2361)) {
                                                    return (
                                                        <Icon
                                                            type="receber"
                                                            title="Receber chamado"
                                                            animated
                                                            onClick={() => changeReceived(
                                                                "Recebeu o chamado",
                                                                props?.card?.id_job,
                                                                props?.card?.id_job_status
                                                            )}
                                                        />
                                                    )
                                                }
                                            })()}

                                            {(() => {
                                                if (props?.card?.mov_url_video) {
                                                    return (
                                                        <Tutorial url={props?.card?.mov_url_video} title={props?.card?.job} />
                                                    )
                                                }
                                            })()}

                                            {(() => {
                                                if ((!window.rs_id_lja || window.rs_id_lja == 0) && !props?.card?.data_adiado && window.rs_permission_apl === 'master' && !props.chamados && (!props?.card?.status_supervisor || props?.card?.status_supervisor == 3)) {
                                                    return (
                                                        <>
                                                            <Editar
                                                                id={props?.card?.id_job}
                                                                id_emp={props?.job?.id_emp}
                                                                lote={props?.card?.lote}
                                                                disabled={(props?.card?.status == 0 || props?.card?.status == 4 || props?.tipoCalendario == 7 ? (/*props?.card?.card_qtd_total > 0*/ false ? true : false) : true)}
                                                                /*disabledTitle={(props?.card?.card_qtd_total > 0 ? 'Não é possível editar cards com jobs internos' : '')}*/
                                                                chamados={props.chamados}
                                                                fases={props.fases}
                                                                visitas={props.visitas}
                                                                aux_form={props?.card?.aux_form}
                                                                model={(props?.tipoCalendario == 9 ? { edit: true } : false)}
                                                                modalTitle={(props?.tipoCalendario == 9 ? 'Editar Modelo' : '')}
                                                                reload={handleReload}
                                                            />

                                                            {(props?.tipoCalendario == 9 ?
                                                                <Editar
                                                                    id={props?.card?.id_job}
                                                                    id_emp={props?.job?.id_emp}
                                                                    lote={props?.card?.lote}
                                                                    disabled={(props?.card?.status == 0 || props?.card?.status == 4 || props?.tipoCalendario == 7 ? (/*props?.card?.card_qtd_total > 0*/ false ? true : false) : true)}
                                                                    chamados={props.chamados}
                                                                    fases={props.fases}
                                                                    visitas={props.visitas}
                                                                    aux_form={props?.card?.aux_form}
                                                                    icon={'clone'}
                                                                    iconTitle={'Copiar'}
                                                                    modalTitle={'Copiar Modelo'}
                                                                    model={{ edit: false }}
                                                                    reload={handleReload}
                                                                />
                                                                : '')}
                                                        </>
                                                    )
                                                }
                                            })()}

                                            {(() => {
                                                if (!props.fases && props?.tipoCalendario != 7 && !props?.card?.job_arquivado && !props?.card?.id_apl.includes('231') && !props.visitas && !props?.card?.data_adiado && !props?.card?.desabilitar?.split(',').includes('2') && (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr)) {
                                                    if (props.chamados && props?.job.tipo_fase == 'Início') {

                                                    } else {
                                                        let devolver = false;

                                                        // VERIFICA SE O EMPREENDIMENTO TÁ CONFIGURADO PARA VOLTAR O CHAMADO AO SOLICITANDO QUANDO OPERADOR RECUSAR
                                                        if (configuracoes[0]?.conf_card) {
                                                            if (JSON.parse(configuracoes[0].conf_card)?.actions?.recusar?.devolver === 1) {
                                                                devolver = true;
                                                            }
                                                        }

                                                        if (devolver) {
                                                            return (
                                                                <Reprovar
                                                                    chamados={props.chamados}
                                                                    id_job={props?.card?.id_job}
                                                                    id_job_status={props?.card?.id_job_status}
                                                                    title="Recusar"
                                                                    job={props?.card?.job}
                                                                    modalTitle={'Recusar - ' + props?.card?.job}
                                                                    tipo="next"
                                                                    tipo_fase="Operação"
                                                                    callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                                                />
                                                            )
                                                        } else {
                                                            return (
                                                                <CheckOperador
                                                                    chamados={props.chamados}
                                                                    fases={props.fases}
                                                                    id_job={props?.card?.id_job}
                                                                    id_job_status={props?.card?.id_job_status}
                                                                    title={(props.chamados ? 'Recusar' : 'Não tem')}
                                                                    modules={props?.optionsModule}
                                                                    card={props?.card}
                                                                    tipo="next"
                                                                    status={2} // REPROVAR
                                                                    callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                                                />
                                                            )
                                                        }
                                                    }
                                                }
                                            })()}

                                            {(() => {
                                                if (
                                                    (
                                                        !props.visitas &&
                                                        (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr) &&
                                                        !props?.card?.data_adiado &&
                                                        !props?.card?.desabilitar?.split(',').includes('4') &&
                                                        !props.chamados &&
                                                        !props.fases &&
                                                        !props?.card?.id_apl.includes('231') &&
                                                        props?.tipoCalendario != 7 &&
                                                        !props?.card?.job_arquivado
                                                    )
                                                    ||
                                                    (
                                                        props?.visitas &&
                                                        !props?.card?.desabilitar?.split(',').includes('4') &&
                                                        (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') &&
                                                        (!configuracoes[0]?.conf_desabilitar || (configuracoes[0]?.conf_desabilitar && configuracoes[0]?.id_par != props?.card?.id_categoria && configuracoes[0]?.conf_desabilitar.includes('4')))
                                                    )
                                                    ||
                                                    (
                                                        props?.visitas &&
                                                        window.rs_permission_apl === 'master'
                                                    )
                                                ) {
                                                    return (
                                                        <Icon
                                                            type="calendar"
                                                            title={(props?.visitas ? 'Adiantar/Adiar' : 'Adiar')}
                                                            animated
                                                            datepicker={true}
                                                            valueStart={(!props.visitas ? new Date(props?.card?.data_job) : '')} // SE FOR VISITAS NÃO SETA A DATA INICIAL DO CALENDÁRIO
                                                            onChange={(e) =>
                                                                setDate(
                                                                    "Adiou o job de " + props?.card?.data_formatada + " para " + cd(e),
                                                                    props?.card?.id_job_status,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    )
                                                }
                                            })()}

                                            {(() => {
                                                if (props.visitas) {
                                                    if (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr) {
                                                        if(!props?.card?.desabilitar?.split(',').includes('2')){
                                                            return(
                                                                <CheckOperador
                                                                    visitas={props.visitas}
                                                                    id_job={props?.card?.id_job}
                                                                    id_job_status={props?.card?.id_job_status}
                                                                    title={'Não tem'}
                                                                    modules={props?.optionsModule}
                                                                    card={props?.card}
                                                                    tipo="next"
                                                                    status={2} // REPROVAR
                                                                    callback={(e) => refreshCalendar()}
                                                                />
                                                            )
                                                        }
                                                    }
                                                }
                                            })()}

                                            {(() => {
                                                if (props.visitas) {
                                                    if (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr || props?.subTipoCalendario === 'store') {
                                                        if ((!configuracoes[0]?.conf_desabilitar || (configuracoes[0]?.conf_desabilitar && !configuracoes[0]?.conf_desabilitar.includes('1') || (configuracoes[0]?.id_par != props?.card?.id_subcategoria && configuracoes[0]?.conf_desabilitar.includes('1')) || (configuracoes[0]?.id_par == props?.card?.id_categoria && props?.card?.data_job > get_date('datetime_sql', new Date()))))) {
                                                            let title_aux;
                                                            let disabled_aux;

                                                            if (props?.card?.data_job.slice(0, 10) > window.currentDateWithoutHour) {
                                                                title_aux = 'Não é possível realizar visita em datas futuras';
                                                                disabled_aux = true;
                                                            } else {
                                                                title_aux = 'Finalizar';
                                                                disabled_aux = false;
                                                            }

                                                            return (      
                                                                <Icon
                                                                    type="check"
                                                                    title={title_aux}
                                                                    disabled={disabled_aux}
                                                                    animated
                                                                    onClick={() =>
                                                                        changeStatus(
                                                                            "Finalizou o " + (props.chamados ? 'chamado' : 'job'),
                                                                            props?.card?.id_job,
                                                                            props?.card?.id_job_status,
                                                                            props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1,
                                                                            undefined,
                                                                            undefined,
                                                                            'next',
                                                                            props?.job.tipo_fase,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            props?.card?.mov_prioridade,
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    }
                                                } else if (props.chamados) {
                                                    if (props?.tipoCalendario != 7 && props?.job.tipo_fase != 'Início' && !props?.card?.desabilitar?.split(',').includes('1') && (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr) && !props?.card?.url_sistema_job) {
                                                        let nivel_aux = 2;

                                                        if (configuracoes[0]) { // VERIFICA SE VEIO CONFIGURACOES DA API
                                                            if (configuracoes[0].conf_chat) { // VERIFICA SE POSSUI CONFIGURAÇÕES PARA O CHAT
                                                                if (JSON.parse(configuracoes[0]?.conf_chat).tipo_fase[props?.job.tipo_fase]) { // VERIFICA SE POSSUI CONFIGURAÇÃO PARA O TIPO DA FASE
                                                                    nivel_aux = JSON.parse(configuracoes[0]?.conf_chat).tipo_fase[props?.job.tipo_fase].nivel_msg; // SETA O NÍVEL DAS MSGS
                                                                }
                                                            }
                                                        }

                                                        return (
                                                            <CheckOperador
                                                                chamados={props.chamados}
                                                                fases={props.fases}
                                                                id_job={props?.card?.id_job}
                                                                id_job_status={props?.card?.id_job_status}
                                                                modules={props?.optionsModule}
                                                                card={props?.card}
                                                                tipo="next"
                                                                nivel={nivel_aux}
                                                                prioridade={props?.card?.mov_prioridade}
                                                                tipo_fase={props?.job.tipo_fase}
                                                                status={props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1}
                                                                callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                                            />
                                                        )
                                                    }
                                                } else {
                                                    if (!props.fases && !props.visitas && !props?.card?.data_adiado && !props?.card?.job_arquivado && props?.tipoCalendario != 7 && !props?.card?.desabilitar?.split(',').includes('1') && (props?.card?.id_loja == window.rs_id_lja || props?.card?.id_usuario == window.rs_id_usr) && !props?.card?.url_sistema_job) {
                                                        return (
                                                            <Icon
                                                                type="check"
                                                                title={(habilitarFinalizar ? 'É necessário finalizar todos os cards internos antes de finalizar o job' : 'Finalizar')}
                                                                disabled={(habilitarFinalizar ? true : false)}
                                                                animated
                                                                onClick={() =>
                                                                    changeStatus(
                                                                        "Finalizou o " + (props.chamados ? 'chamado' : 'job'),
                                                                        props?.card?.id_job,
                                                                        props?.card?.id_job_status,
                                                                        props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1,
                                                                        undefined,
                                                                        undefined,
                                                                        'next',
                                                                        props?.job.tipo_fase,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        props?.card?.mov_prioridade
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }
                                                }
                                            })()}

                                            {(() => {
                                                if (props.chamados && props?.job.tipo_fase != 'Início' && props?.card?.job_execucao != 1 && props?.card?.id_usuario == window.rs_id_usr) {
                                                    return (
                                                        <>
                                                            <Icon
                                                                type="executar"
                                                                title="Executar chamado"
                                                                animated
                                                                onClick={() =>
                                                                    changeExecuting(
                                                                        'Marcou o chamado como "Em execução"',
                                                                        props?.card?.id_job_status,
                                                                        1
                                                                    )
                                                                }
                                                            />


                                                        </>
                                                    )
                                                }
                                            })()}

                                            {/* {(() => {
                                    if(props.chamados && props?.job.tipo_fase == 'Operação' && props?.card?.id_usuario == window.rs_id_usr){                                                                    
                                        return(
                                            <Solicitar
                                                chamados={props.chamados}
                                                fases={props.fases}
                                                id_job={props?.card?.id_job}
                                                id_job_status={props?.card?.id_job_status}
                                                modalTitle={'Solicitar complemento - ' +props?.card?.job}
                                                callback={(e) => (props.chamados ? refreshCalendar() : handleRefreshCard(e))}
                                            />
                                        )
                                    }
                                })()}  */}
                                        </>
                                    );
                                }
                            }
                        })()}

                        {(() => {
                            if (props.fases && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master')) {
                                return (
                                    <>
                                        {(props?.indexSlide != 0 && (props?.card?.id_usuario == window.rs_id_usr || props?.card?.id_loja == window.rs_id_lja || (!props?.card?.id_usuario && !props?.card?.id_loja)) ? // SE FOR DIFERENTE DA PRIMEIRA COLUNA
                                            (props?.indexSlide == props?.jobs.length - 1 && props?.card?.status != 0 ? // SE FOR A ÚLTIMA COLUNA E O STATUS FOR EM ANDAMENTO (0)
                                                <Icon
                                                    type="prev"
                                                    title="Voltar fase"
                                                    animated
                                                    onClick={() => changeStatusSupervisor(
                                                        "Voltou o card depois de finalizado",
                                                        props?.card?.id_job,
                                                        props?.card?.id_job_status,
                                                        3,
                                                        undefined,
                                                        undefined,
                                                        'prev',
                                                        props?.job.tipo_fase
                                                    )}
                                                />
                                                :
                                                <Icon
                                                    type="prev"
                                                    title="Voltar fase"
                                                    animated
                                                    onClick={() => changePhase(
                                                        'Voltou a fase',
                                                        props?.card?.id_job,
                                                        props?.card?.id_job_status,
                                                        'prev',
                                                        props?.job.tipo_fase
                                                    )}
                                                />
                                            )
                                            : '')}

                                        {(props?.indexSlide != props?.jobs.length - 1 && (props?.card?.id_usuario == window.rs_id_usr || props?.card?.id_loja == window.rs_id_lja || (!props?.card?.id_usuario && !props?.card?.id_loja)) ?
                                            <Icon
                                                type="next"
                                                title={'Avançar'}
                                                onClick={() => changePhase(
                                                    'Avançou de fase',
                                                    props?.card?.id_job,
                                                    props?.card?.id_job_status,
                                                    'next',
                                                    props?.job.tipo_fase
                                                )}
                                                animated
                                            />
                                            : '')}

                                        {/* BOTÃO DE ARQUIVAR SOMENTE PARA QUEM TEM NÍVEL DE ACESSO MASTER */}
                                        {(window.rs_permission_apl === 'master' ?
                                            <Icon
                                                type={(props?.card?.job_arquivado === 1 ? 'unarchive' : 'archive')}
                                                title={(props?.card?.job_arquivado === 1 ? 'Desarquivar' : 'Arquivar')}
                                                onClick={() => handleArchive(props?.card.id_job, props?.card.id_job_status, (props?.card?.job_arquivado === 1 ? 0 : 1))}
                                                animated
                                            />
                                        : '')}

                                        {((props?.card?.status == 0 || props?.card?.status == 4) && (props?.card?.id_usuario == window.rs_id_usr || props?.card?.id_loja == window.rs_id_lja || (!props?.card?.id_usuario && !props?.card?.id_loja)) ? // VERIFICA SE ESTÁ NA ÚLTIMA COLUNA PARA MOSTRAR O BOTÃO DE FINALIZAR
                                            <Icon
                                                type="check"
                                                title={(habilitarFinalizar ? 'É necessário finalizar todos os cards internos antes de finalizar o job' : 'Finalizar')}
                                                disabled={(habilitarFinalizar ? true : false)}
                                                animated
                                                onClick={() =>
                                                    changeStatus(
                                                        "Finalizou o card",
                                                        props?.card?.id_job,
                                                        props?.card?.id_job_status,
                                                        props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        props?.job.tipo_fase,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        props?.card?.mov_prioridade
                                                    )
                                                }
                                            />
                                            : '')}
                                    </>
                                )
                            }
                        })()}
                    </>
                }
                background={background}
                alert={alertHour}
                disabled={disabled}
                description={props?.card?.descricao}
                files={props?.card?.anexos}
                fases={props?.fases}
                chamados={props?.chamados}
                visitas={props?.visitas}
                iframe={
                    props?.card?.url_sistema_job && !props?.card?.sistema_job_api && !props?.card?.adiado && props?.card?.sistema_visivel == 1
                        ? window.host + "/systems/integration-react/sistema.php?loja_id=" +
                        (props?.card?.id_loja ?? '') +
                        "&usuario_id=" + (props?.card?.id_usuario ?? '') +
                        "&data=" + props?.card?.data_formatada +
                        "&mes=" + props?.card?.data_formatada.split('/')[1] +
                        "&ano=" + props?.card?.data_formatada.split('/')[2] +
                        "&hora=" + props?.card?.hora_limite_formatada +
                        "&sistema=" + props?.card?.url_sistema_job +
                        "&sistema_job_id=" + props?.card?.sistema_job_id +
                        "&sistema_aux=" + system_type +
                        "&system_integration=" + (system_integration ? system_integration.replaceAll('"', "'") : '') +
                        "&sistema_dado_aux=" + (props?.card?.job_dado_aux ?? '') +
                        "&status_job=" + job_status +
                        "&aux_job=" + aux_job +
                        "&filter_id_module=" + (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule) +
                        "&aba=calendario&token="+window.token
                        : ""
                }
                integration={
                    (() => {
                        let interaction = true;

                        if (props?.card?.status_supervisor == 2) {
                            if (props?.card?.id_loja) {
                                if (props?.card?.id_loja != window.rs_id_lja) {
                                    interaction = false;
                                } else {
                                    interaction = 'custom';
                                }
                            }
                            if (props?.card?.id_usuario) {
                                if (props?.card?.id_usuario != window.rs_id_usr) {
                                    interaction = false;
                                } else {
                                    interaction = 'custom';
                                }
                            }
                        } else { // SE FOR STATUS REABERTO PELO SUPERVISOR (3)
                            if (props?.card?.status == 1 || props?.card?.status == 3) { // SE FOR STATUS FINALIZADO (1) OU FINALIZADO COM ATRASO (3)
                                interaction = false;
                            } else if(props?.card?.status == 2) {
                                interaction = false;
                            } else {
                                if (props?.card?.id_loja) {
                                    if (props?.card?.id_loja != window.rs_id_lja) {
                                        interaction = false;
                                    }
                                }
                                if (props?.card?.id_usuario) {
                                    if (props?.card?.id_usuario != window.rs_id_usr) {
                                        interaction = false;
                                    }
                                }
                            }
                        }

                        if (!props?.card?.data_adiado) {
                            if (props?.card?.sistema_job_api && props?.card?.sistema_visivel == 1) {
                                if (props?.card?.sistema_job_id == 1) { // CHECKLIST
                                    let submit = true;
                                    let submitInteraction = true;
                                    let submitTitle = '';
                                    
                                    if (habilitarFinalizar) {
                                        submitInteraction = false;
                                        submitTitle = 'É necessário finalizar todos os cards internos antes de enviar o checklist';
                                    } else {
                                        if (props?.card?.status == 1 || props?.card?.status == 3) {
                                            submit = false;
                                        } else {
                                            if (props?.card?.id_loja) {
                                                if (props?.card?.id_loja != window.rs_id_lja) {
                                                    submit = false;
                                                } else {
                                                    submit = 'custom';
                                                }
                                            }
                                            if (props?.card?.id_usuario) {
                                                if (props?.card?.id_usuario != window.rs_id_usr) {
                                                    submit = false;
                                                } else {
                                                    submit = 'custom';
                                                }
                                            }
                                        }
                                    }
                                    
                                    return (
                                        <div className="mt-3">
                                            <Checklist
                                                integration={true}
                                                checklist_id={system_type}
                                                job_dado_aux1={props?.card?.job_dado_aux1}
                                                loja_id={(props?.card?.id_loja ?? system_type_1)}
                                                callback={(e) => finalizaJob(props?.card?.id_job, props?.card?.id_job_status, (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1), e, props?.card?.mov_prioridade)}
                                                submit={{
                                                    enabled: submit,
                                                    interaction: submitInteraction,
                                                    title: submitTitle
                                                }}
                                                interaction={interaction}
                                                job={
                                                    {
                                                        job_id: aux_job.split('|||')[0],
                                                        data_job: aux_job.split('|||')[1],
                                                        sistema_dado_aux: props?.card?.job_dado_aux
                                                    }
                                                }
                                                job_status_supervisor={props?.card?.status_supervisor}
                                                job_title={props?.card?.job}

                                                btn_respondido={props?.visitas && !props?.card?.job_dado_aux}
                                            />
                                        </div>
                                    )
                                } else if (props?.card?.sistema_job_id == 28) { // CHECKLIST LARAVEL
                                    let submit = true;
                                    let submitInteraction = true;
                                    let submitTitle = '';
                                    
                                    if (habilitarFinalizar) {
                                        submitInteraction = false;
                                        submitTitle = 'É necessário finalizar todos os cards internos antes de enviar o checklist';
                                    } else {
                                        if (props?.card?.status == 1 || props?.card?.status == 3) {
                                            submit = false;
                                        } else {
                                            if (props?.card?.id_loja) {
                                                if (props?.card?.id_loja != window.rs_id_lja) {
                                                    submit = false;
                                                } else {
                                                    submit = 'custom';
                                                }
                                            }
                                            if (props?.card?.id_usuario) {
                                                if (props?.card?.id_usuario != window.rs_id_usr) {
                                                    submit = false;
                                                } else {
                                                    submit = 'custom';
                                                }
                                            }
                                        }
                                    }
                                    
                                    return(
                                        <div className="mt-3">
                                            <ChecklistLaravel
                                                integration={true}
                                                checklist_id={system_type}
                                                job_dado_aux1={props?.card?.job_dado_aux1}
                                                loja_id={(props?.card?.id_loja ?? system_type_1)}
                                                // funcionario_id={funcionario_id_aux}
                                                callback={(e) => finalizaJob(props?.card?.id_job, props?.card?.id_job_status, (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1), e, props?.card?.mov_prioridade)}
                                                submit={{
                                                    enabled: submit,
                                                    interaction: submitInteraction,
                                                    title: submitTitle
                                                }}
                                                interaction={interaction}
                                                job={
                                                    {
                                                        job_id: aux_job.split('|||')[0],
                                                        status: props?.card?.status,
                                                        status_sup: props?.card?.status_sup,
                                                        data_job: aux_job.split('|||')[1],
                                                        sistema_dado_aux: props?.card?.job_dado_aux
                                                    }
                                                }
                                                job_title={props?.parent?.titulo}
                                                btn_respondido={props?.visitas && !props?.parent?.job_dado_aux}
                                            />
                                        </div>
                                    )
                                } else if (props?.card?.sistema_job_id == 24) { // TELEFONE LOJAS
                                    return (
                                        <div className="mt-3">
                                            <Telefones
                                                integrated
                                                usuario={props?.card?.id_usuario}
                                                loja={(props?.card?.id_loja ?? system_type_1)}
                                                callback={(e) => finalizaJob(props?.card?.id_job, props?.card?.id_job_status, (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1), e, props?.card?.mov_prioridade)}
                                                interaction={interaction}
                                            />
                                        </div>
                                    )
                                }
                            }
                        }
                    })()
                }
                iframeName={"job_" + props?.card?.id_job + "_" + props?.card?.id_job_status + "_" + (props?.card?.data_job < get_date('datetime_sql', new Date()) ? 3 : 1)}
            >
                {(troca_operador ? // VERIFICA SE PODE HAVER A TROCA DE OPERADOR
                    <div className="mt-3">
                        <Form>
                            <TrocaOperador
                                label={(props?.indexSlide == 0 && props.chamados ? 'Encaminhar ao operador' : 'Trocar de operador')}
                                options={(props?.job.tipo_fase == 'Check' ? props?.optionsCheck : props?.optionsOperator)}
                                fases={props?.fases}
                                chamados={props?.chamados}
                                visitas={props?.visitas}
                                params={{
                                    id_modulo: props?.card?.id_modulo,
                                    id_job: props?.card?.id_job,
                                    id_job_status: props?.card?.id_job_status,
                                    tipo_fase: props?.job?.tipo_fase,
                                    ativ_desc: (props?.indexSlide == 0 ? 'Encaminhou o chamado a(o)' : 'Trocou de operador para'),
                                    filterModule: (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
                                    filter_subtype: props?.subTipoCalendario
                                }}
                                onChange={handleChangeOperator}
                            // refreshCard={handleRefreshCard}
                            />

                            {(
                                props.chamados &&
                                    props?.job.tipo_fase == 'Operação' ?
                                    props?.card.id_usuario == window.rs_id_usr ||
                                        window.rs_permission_apl == 'master'
                                        ?
                                        <TrocaLoja
                                            label={(props?.indexSlide == 0 && props.chamados ? 'Encaminhar a loja' : props?.subTipoCalendario == 'user' ? 'Trocar de loja' : 'Trocar de usuário' )}
                                            options={(props?.job.tipo_fase == 'Check' ? props?.optionsCheck : props?.optionsOperator)}
                                            chamados={props?.chamados}
                                            params={{
                                                id_modulo: props?.card?.id_modulo,
                                                id_job: props?.card?.id_job,
                                                id_job_status: props?.card?.id_job_status,
                                                tipo_fase: props?.job?.tipo_fase,
                                                ativ_desc: (props?.indexSlide == 0 ? 'Encaminhou o chamado a(o)' : 'Trocou de operador para'),
                                                filterModule: (props?.card?.id_modulo ? props?.card?.id_modulo : filterModule),
                                                filter_subtype: props?.subTipoCalendario
                                            }}
                                            onChange={handleChangeOperator}
                                        />
                                        : ''
                                    : <></>
                            )}
                            {(props.chamados ?
                                <SelectReact
                                    label="Trocar de módulo"
                                    name="troca_modulo"
                                    options={props?.optionsModule}
                                    value={categoriaSelected}
                                    isRtl={true}
                                    required={false}
                                    onChange={(e) => (
                                        setCategoriaSelected(e.value),
                                        changeCategoria(props?.card?.id_job, props?.card?.id_job_status, e.value, `Alterou o módulo: ` + props?.optionsModule.filter((item) => item.value === e.value)[0].label, props?.card?.tipo_fase)
                                    )}
                                />
                                : '')}
                        </Form>
                    </div>
                    : '')}

                {(troca_status && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') ?
                    <TrocaStatus
                        id_mov={props?.card?.id_job_status}
                        id_job={props?.card?.id_job}
                        value={props?.card?.id_card_status}
                    />
                    : '')}
            </Card>
        )
    )
}
