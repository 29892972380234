import { useState, useEffect, createRef } from 'react';
import style from './textarea.module.scss';

//EDITOR
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import './style.css';

export default function Textarea(props) {
    // ESTADOS
    const [focus, setFocus] = useState(null);

    // REF
    const ref = createRef();

    // FUNÇÃO AO FOCAR NO INPUT
    const handleFocus = () => {
        setFocus(true);
    }

    // FUNÇÃO AO TIRAR FOCO DO INPUT
    const handleFocusOut = () => {    
        if(focus){
            setFocus(false);
        }
    }

    useEffect(() => {
        if(focus === false) {
            if (props.onFocusOut) {
                props.onFocusOut(true);
            }
        }
    }, [focus])

    //VARIAVEIS DO EDITOR

    const modules = {
        toolbar: [
            [{
                'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc",
                    "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200",
                    "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
            }],
            ['bold', 'italic', 'underline'],
            [{'align': ['', 'center', 'right', 'justify']}],
            (props?.editor?.size ? [{ 'size': ['small', false, 'large', 'huge'] }] : []),
            (props?.editor?.font ? [{ 'font': [] }] : []),
            ['link']
        ]
    };

    const formats = [
        'header',
        'color',
        'bold', 'italic', 'underline',
        'align',
        'link',
        'size',
        'font',
        'image'
    ];

    //DESATIVAR O CORRETOR
    useEffect(()=>{
        ref?.current?.editor?.root?.setAttribute('spellcheck', 'false');
    },[])

    return (
        <>
            {
                props.editor ?
                    <div className="position-relative">
                        <ReactQuill
                            ref={ref}
                            placeholder={(props.label || props.placeholder ? (props.placeholder ? props.placeholder : props.label) : 'Textarea') + ': ' + (props.required === false ? '' : '*')}
                            value={(props.value ? props.value : '')}
                            onChange={props.onChange}
                            className={style.input}
                            modules={modules}
                            required={props.required === false ? false : true}
                            formats={formats}
                            onKeyDown={props?.onKeyDown}
                        />
                        <textarea
                            onKeyDown={props?.onKeyDown}
                            required={props.required === false ? false : true}
                            value={(props.value ? props.value : '')}
                            onChange={() => console.log('')}
                            className={style.hidden}
                        ></textarea>
                    </div>
                    :
                    <div
                        data-textarea
                        className={
                            style.box__textarea + ' ' +
                            (props.fullWidth ? 'w-100' : '') + ' ' +
                            (props.className ? props.className : '') + ' ' +
                            (props.type == 'checkbox' ? style.checkbox : '') + ' ' +
                            (focus ? style.box__textarea_focus : '') + ' ' +
                            (props.alert ? style.border__alert : '') + ' ' +
                            (props.disabled ? style.disabled : '') + ' ' +
                            (props.readonly ? style.readonly : '')
                        }
                    >
                        {(() => {
                            return (
                                <textarea
                                    ref={ref}
                                    className={style.input}
                                    type={(props.type === 'date' ? 'text' : props.type)}
                                    name={props.name}
                                    id={props.id}
                                    placeholder={(props.label || props.placeholder ? (props.placeholder ? props.placeholder : props.label) : 'Textarea') + ': ' + (props.required === false ? '' : '*')}
                                    onFocus={handleFocus}
                                    onBlur={handleFocusOut}
                                    value={(props.value ? props.value : '')}
                                    checked={props.checked}
                                    onChange={props.onChange}
                                    required={props.required === false ? false : true}
                                    style={{ height: (props.height ? props.height : '') }}
                                ></textarea>
                            )
                        })()}
                    </div>
            }
        </>
    );
}
